import axios from "../../helpers/axios/axios";
import { BASE_URL, BASE_URL_PAYMENT } from "../../helpers/common/config";
import * as actionTypes from "../actions/types";
import { setLoading, setSnackBar } from "./app";

const setToken = (payload) => {
  return {
    type: actionTypes.SET_MT_TOKEN,
    data: payload,
  };
};
const setGrossAmount = (payload) => {
  return {
    type: actionTypes.SET_MT_GROSS_AMOUNT,
    data: payload,
  };
};

export const getHargaPoli = (body, action) => {
  const url = "/api/layanan/harga-daftar";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    return axios
      .get(BASE_URL + url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setGrossAmount(payload.data.ref_prod_hargajual));
        }
      })
      .catch((err) => {
        return dispatch(setGrossAmount(0));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const generateBillingMT = (body, navigator) => {
  return (dispatch, state) => {
    const midtrans = state().midtrans;
    const bahasa = state().app.bahasa;
    let token = midtrans.token || body.token;
    let order_id = body.order_id;
    if (token) {
      return window.snap.pay(token, {
        onSuccess: function (result) {
          /* You may add your own implementation here */
          alert("payment success!");
          console.log(result);
        },
        onPending: function (result) {
          /* You may add your own implementation here */
          // alert("wating your payment!");
          // window.open('https://google.com')
          navigator && navigator(`/midtrans/${order_id}`);
          console.log(result);
        },
        onError: function (result) {
          /* You may add your own implementation here */
          // alert("payment failed!");
          navigator && navigator(`/midtrans/${order_id}`);
          console.log(result);
        },
        onClose: function () {
          // window.open('https://google.com')
          /* You may add your own implementation here */
          // alert("you closed the popup without finishing the payment");
          return dispatch(
            setSnackBar({
              open: true,
              severity: "info",
              message:
                bahasa === "indonesia"
                  ? "Transaksi dibatalkan"
                  : "Transaction cancelled",
            })
          );
        },
      });
    }
    return axios
      .get(BASE_URL + "/api/layanan/harga-daftar", {
        params: {
          id_layanan: body.id_layanan,
        },
      })
      .then((payload) => {
        if (payload.data) {
          dispatch(setGrossAmount(payload.data.ref_prod_hargajual));
          console.log(`${BASE_URL_PAYMENT}/midtrans/generate`);
          return axios
            .post(`${BASE_URL_PAYMENT}/midtrans/generate`, {
              gross_amount: payload.data.data.ref_prod_hargajual,
              rkun_id: body.rkun_id,
            })
            .then((payload) => {
              if (!payload.data.data) {
                console.log(payload);
                return console.log("Gagal");
              }
              token = payload.data.data.token;
              dispatch(setToken(token));
              return window.snap.pay(token, {
                onSuccess: function (result) {
                  /* You may add your own implementation here */
                  // alert("payment success!");
                  navigator && navigator(`/midtrans/${result.order_id}`);
                  console.log(result);
                },
                onPending: function (result) {
                  /* You may add your own implementation here */
                  // alert("wating your payment!");
                  // window.open('https://google.com')
                  navigator && navigator(`/midtrans/${result.order_id}`);

                  console.log(result);
                },
                onError: function (result) {
                  /* You may add your own implementation here */
                  // alert("payment failed!");
                  navigator && navigator(`/midtrans/${result.order_id}`);

                  console.log(result);
                },
                onClose: function () {
                  // window.open('https://google.com')
                  /* You may add your own implementation here */
                  // alert("you closed the popup without finishing the payment");
                  return dispatch(
                    setSnackBar({
                      open: true,
                      severity: "info",
                      message:
                        bahasa === "indonesia"
                          ? "Transaksi dibatalkan"
                          : "Transaction cancelled",
                    })
                  );
                },
              });
            });
        }
      })
      .catch((err) => {
        return dispatch(setGrossAmount(0));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
const setStatusTransaksi = (payload) => {
  return {
    type: actionTypes.SET_MT_STATUS_TRX,
    data: payload.data,
  };
};
export const checkPayment = (body, fail) => {
  const url = BASE_URL_PAYMENT + "/midtrans/check-payment";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, {
        order_id: body.order_id,
      })
      .then((payload) => {
        return dispatch(setStatusTransaksi(payload.data));
      })
      .catch((err) => {
        fail && fail();
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

import React, { useEffect, useRef } from "react";
import { BASE_URL } from "../../helpers/common/config";

export default function AudioPlayer({ play, src }) {
  const ref = useRef();

  //   const handleAudio = ({ play }) => {
  //     ref.current.play();
  //   };

  useEffect(() => {
    if (ref.current) {
      if (play) {
        ref.current.play();
      } else {
        ref.current.pause();
      }
    }
    return () => {};
  }, [play, src]);

  return (
    <>
      <audio
        controls
        ref={ref}
        autoPlay
        loop
        playsInline
        style={{
          display: "none",
        }}
      >
        <source src={`${src}`} type="audio/mp3" />
      </audio>
    </>
  );
}

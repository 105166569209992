import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import doctorImg from "../../../assets/doctors.png";

const useStyles = makeStyles(() => ({
  img: {
    // width: "100%",
    // height: "auto",
    height: "100px",
    objectFit: "cover",
    objectPosition: "center",
    // backgroundColor: "#000",
  },
  root: {
    cursor: "pointer",
    padding: "8px",
  },
  textContent: {
    padding: "0 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const Medium = (props) => {
  const classes = useStyles();
  const {
    title = "Undefined",
    text = "Undefined",
    img,
    click = () => {},
  } = props;
  return (
    <Paper {...props} className={classes.root} onClick={click}>
      <Grid container direction={"column"}>
        <Grid item container justifyContent={"center"} alignItems="center">
          <img
            className={classes.img}
            src={img}
            alt={title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = doctorImg;
            }}
          />
        </Grid>
        <Grid item>
          <Typography
            variant="body1"
            noWrap
            overflow="hidden"
            textOverflow="ellipsis"
            width="70%"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">{text}</Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Medium;

import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  hasil: "",
  rangeIMT: "",
  hasilText: "",
  klasifikasi: "",
  berat: "",
  tinggi: "",
  anjuran: "",
};

const hitungIMT = (state, action) => {
  return updateObject(state, {
    hasil: action.data.hasil,
    rangeIMT: action.data.rangeIMT,
    hasilText: action.data.hasilText,
    klasifikasi: action.data.klasifikasi,
    anjuran: action.data.anjuran,
  });
};

const resetIMT = (state, action) => {
  return initialState;
};

const setTBBB = (state, action) => {
  return updateObject(state, {
    [action.data.key]: action.data.value,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_HASIL_IMT:
      return hitungIMT(state, action);
    case actionTypes.SET_VARIABLE_IMT:
      return setTBBB(state, action);
    case actionTypes.RESET_IMT:
      return resetIMT(state, action);
    default:
      return state;
  }
};

export default reducer;

import axios from "../../helpers/axios/axios";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import { setLoading } from "./index";
import moment from "moment";

const setPendidikan = (payload) => {
  return {
    type: actionTypes.SET_PENDIDIKAN,
    data: payload.data,
  };
};

const setPekerjaan = (payload) => {
  return {
    type: actionTypes.SET_PEKERJAAN,
    data: payload.data,
  };
};

const setAgama = (payload) => {
  return {
    type: actionTypes.SET_AGAMA,
    data: payload.data,
  };
};

const setStatusNikah = (payload) => {
  return {
    type: actionTypes.SET_STATUS_NIKAH,
    data: payload.data,
  };
};

const setJenisIdentitas = (payload) => {
  return {
    type: actionTypes.SET_JENIS_ID,
    data: payload.data,
  };
};

const setDataKamar = (payload) => {
  return {
    type: actionTypes.SET_KAMAR,
    data: payload.data,
  };
};

const setProduk = (payload) => {
  return {
    type: actionTypes.SET_PRODUK,
    data: payload.data,
  };
};

const setListDoctor = (payload) => {
  return {
    type: actionTypes.SET_LIST_DOCTOR,
    data: payload.data,
  };
};

const setListPoli = (payload) => {
  return {
    type: actionTypes.SET_LIST_POLI,
    data: payload.data,
  };
};

export const setDetailDoctor = (payload, action) => {
  // action && action();
  return {
    type: actionTypes.SET_DETAIL_DOCTOR,
    data: payload.data,
  };
};

const setPropinsi = (payload) => {
  return {
    type: actionTypes.SET_PROPINSI,
    data: payload.data,
  };
};
const setKotamadya = (payload) => {
  return {
    type: actionTypes.SET_KOTAMADYA,
    data: payload.data,
  };
};
const setKecamatan = (payload) => {
  return {
    type: actionTypes.SET_KECAMATAN,
    data: payload.data,
  };
};
const setKelurahan = (payload) => {
  return {
    type: actionTypes.SET_KELURAHAN,
    data: payload.data,
  };
};
const setJadwalDokter = (payload) => {
  return {
    type: actionTypes.SET_JADWAL_DOKTER,
    data: payload.data,
  };
};
const setPaketMCU = (payload) => {
  return {
    type: actionTypes.SET_PAKET_MCU,
    data: payload.data,
  };
};
const setListPenanggung = (payload) => {
  return {
    type: actionTypes.SET_LIST_PENANGGUNG,
    data: payload.data,
  };
};
export const getPendidikan = (action) => {
  const url = BASE_URL + "/api/master/pendidikan";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPendidikan(payload.data));
        }
        return dispatch(setPendidikan([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {});
  };
};

export const getAgama = (action) => {
  const url = BASE_URL + "/api/master/agama";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setAgama(payload.data));
        }
        return dispatch(setAgama([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPekerjaan = (action) => {
  const url = BASE_URL + "/api/master/pekerjaan";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPekerjaan(payload.data));
        }
        return dispatch(setPekerjaan([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getJenisIdentitas = (action) => {
  const url = BASE_URL + "/api/master/jenis-identitas";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setJenisIdentitas(payload.data));
        }
        return dispatch(setJenisIdentitas([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getStatusNikah = (action) => {
  const url = BASE_URL + "/api/master/status-nikah";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setStatusNikah(payload.data));
        }
        return dispatch(setStatusNikah([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDataKamar = (action) => {
  const url = BASE_URL + "/api/simrs/data_kamar";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setDataKamar(payload.data));
        }
        return dispatch(setDataKamar([]));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDataProduk = (action) => {
  const url = BASE_URL + "/api/master/produk";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setProduk(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDokterList = (body, action) => {
  const url = BASE_URL + "/api/doctor/list";
  return (dispatch, state) => {
    dispatch(setLoading(true));

    axios
      .get(url, {
        params: {
          layanan_id: body.layananId,
          kdpoli: body.kdpoli,
          nama_dokter: body.namaDokter,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setListDoctor(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

const setDokterKoas = (payload) => {
  return {
    type: actionTypes.SET_DOKTER_KOAS,
    data: payload.data,
  };
};

export const getDokterKoas = (body, action) => {
  const url = BASE_URL + "/api/doctor/dokter-koas";
  return (dispatch, state) => {
    dispatch(setLoading(true));

    axios
      .get(url, {
        params: {
          nama_dokter: body.namaDokter,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setDokterKoas(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPoliList = (body) => {
  let url = BASE_URL + "/api/doctor/poli";
  return (dispatch, state) => {
    let userAge = state().auth.user_umur;
    if (Number(userAge) >= 60) {
      url = BASE_URL + "/api/doctor/poli-lansia";
    }
    axios
      .get(url, {
        params: {
          type: body ? body.type : null,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setListPoli(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        // dispatch(setLoading(false));
      });
  };
};

export const getPropinsi = (action) => {
  const url = BASE_URL + "/api/master/propinsi";
  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPropinsi(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getKotamadya = (propinsi_id) => {
  const url = BASE_URL + "/api/master/kotamadya";
  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          propinsi_id: propinsi_id,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setKotamadya(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getKecamatan = (kotamadya_id) => {
  const url = BASE_URL + "/api/master/kecamatan";
  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          kotamadya_id: kotamadya_id,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setKecamatan(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getKelurahan = (kecamatan_id) => {
  const url = BASE_URL + "/api/master/kelurahan";

  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          kecamatan_id: kecamatan_id,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setKelurahan(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getJadwalDokter = ({ doctor_id, tanggal }) => {
  const url = BASE_URL + "/api/doctor/jadwal";
  if (!tanggal) {
    tanggal = moment().format("YYYY-MM-DD");
  }
  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          peg_id: doctor_id,
          tanggal: tanggal,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setJadwalDokter(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPaketMCU = ({ mcu_id, mcu_name }) => {
  const url = BASE_URL + "/api/product/paket-mcu";

  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          mcu_id: mcu_id,
          mcu_name: mcu_name,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setPaketMCU(payload.data));
        }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getNotifError = () => {
  const url = BASE_URL + "/api/layanan/latest-error";

  return (dispatch, state) => {
    axios
      .get(url)
      .then((payload) => {
        // if (payload.data) {
        //   return dispatch(setPaketMCU(payload.data));
        // }
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPoliBPJS = (body, action) => {
  const url = BASE_URL + "/api/master/poli-bpjs/cari";

  return (dispatch, state) => {
    axios
      .get(url, {
        params: {
          kdpoli: body.kd_poli,
          jenis_kunjungan: body.jenis_kunjungan,
        },
      })
      .then((payload) => {
        let arr = [];
        if (payload.data.data) {
          payload.data.data.map((item) => {
            return arr.push(item);
          });
        }
        action && action();
        dispatch(
          setListPoli({
            data: arr,
          })
        );
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDokterBPJS = (body, action) => {
  const url = BASE_URL + "/api/doctor/dokter-bpjs";
  const url2 = BASE_URL + "/api/layanan/tutup-poli";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    dispatch(
      setListDoctor({
        data: [],
      })
    );
    return axios
      .get(url2, {
        params: {
          id_layanan: body.layananId,
          tanggal: body.tanggal,
        },
      })
      .then(() => {
        return axios
          .get(url, {
            params: {
              id_layanan: body.layananId,
              kd_poli: body.kd_poli,
              tanggal: body.tanggal,
            },
          })
          .then((payload) => {
            if (payload.data) {
              return dispatch(setListDoctor(payload.data));
            }
          })
          .catch((err) => {
            if (body.tanggal) {
              action && action("Doctor Absent / Dokter Tidak Tersedia");
            }
            return dispatch(
              setListDoctor({
                data: [],
              })
            );
          });
      })
      .catch((err) => {
        if (body.tanggal) {
          action && action("Poli Tutup / Poli Closed");
        }
        dispatch(setLoading(false));
        return dispatch(
          setListDoctor({
            data: [],
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getPenanggung = (body, action) => {
  const url = BASE_URL + "/api/master/penanggung";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url)
      .then((payload) => {
        if (payload.data) {
          return dispatch(setListPenanggung(payload.data));
        }
      })
      .catch((err) => {
        return dispatch(
          setListPenanggung({
            data: [],
          })
        );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const getDetailDokter = (body, action) => {
  const url = BASE_URL + "/api/doctor/detail-dokter";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          peg_id: body,
        },
      })
      .then((payload) => {
        if (payload.data) {
          action && action();
          return dispatch(setDetailDoctor(payload.data));
        }
      })
      .catch((err) => {
        return dispatch(setDetailDoctor(""));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const findMaintenance = (action) => {
  const url = BASE_URL + "/api/master/maintenance";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          app_name: "rsudck-web",
        },
      })
      .then((payload) => {
        if (payload.data && payload.data.data) {
          action &&
            action({
              title: payload.data.data.mntce_title,
              subtitle: payload.data.data.mntce_subtitle,
            });
        }
      })
      .catch((err) => {})
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

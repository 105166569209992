import React from "react";
import { connect } from "react-redux";
import { resetSnackBar } from "../../redux/actions/app";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

function SnackBarCustom(props) {
  const { stateSnackBar, actResetSnackBar } = props;
  const handleClose = () => {
    actResetSnackBar();
  };
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={stateSnackBar.open}
        onClose={handleClose}
        autoHideDuration={1000 * 5}
        // message={stateSnackBar.message}
        // key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={stateSnackBar.severity}>
          {stateSnackBar.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    stateSnackBar: state.app.snackBar,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actResetSnackBar: () => dispatch(resetSnackBar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SnackBarCustom);

import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React from "react";
import { connect } from "react-redux";
import { resetAppointment, setAppointment } from "../../redux/actions";
import CustomInput from "../../components/CustomInput";
import axios from "axios";
import { BASE_URL } from "../../helpers/common/config";
import moment from "moment";

function CreateControlPage(props) {
  const { stateAppointment, actSetAppointment, actResetAppointment } = props;
  const tanggal = moment().format("YYYY-MM-DD");
  const [sep, setSep] = React.useState([]);

  const [msg, setMsg] = React.useState("");
  const handleCreate = (no_sep) => {
    return axios
      .get(BASE_URL + "/api/bpjs/create-kontrol", {
        params: {
          no_sep: no_sep,
          tanggal: tanggal,
          no_mr: stateAppointment.no_mr,
        },
      })
      .then((payload) => {
        if (
          payload.data &&
          payload.data.metaData &&
          payload.data.metaData.code === 200
        ) {
          setSep([]);
          actResetAppointment();
          return setMsg("Berhasil Buat Kontrol");
        } else if (payload.data && payload.data.metaData) {
          return setMsg(payload.data.metaData.message);
        }
      })
      .catch((err) => {
        console.log(err);
        setMsg("Error BPJS");
      });
  };
  const handleSEP = () => {
    return axios
      .get(
        BASE_URL +
          `/api/bpjs/historysep?noka=${stateAppointment.no_kartu}&tgl1=${tanggal}`
      )
      .then((payload) => {
        if (
          payload.data &&
          payload.data.metaData &&
          payload.data.metaData.code === 200
        ) {
          return setSep(payload.data.response);
        } else if (payload.data && payload.data.metaData) {
          setMsg(payload.data.metaData.message);
        }
        return setSep([]);
      })
      .catch((err) => {
        setMsg("Error BPJS");
        return setSep([]);
      });
  };
  return (
    <>
      <Container
        sx={{
          paddingTop: "32px",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Nomor Kartu BPJS"
              fullWidth
              value={stateAppointment.no_kartu}
              error={!stateAppointment.no_kartu}
              // disabled={!stateAppointment.jenis_kunjungan}
              onChange={(e) =>
                actSetAppointment({
                  no_kartu: e.target.value,
                })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <CustomInput
              tipe="no_mr"
              value={stateAppointment.no_mr}
              onChange={(e) =>
                actSetAppointment({
                  no_mr: e.target.value,
                })
              }
              id="no_mr"
              name="no_mr"
              label="No MR"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Button variant="contained" onClick={handleSEP}>
              Cari History SEP
            </Button>
          </Grid>
          <Grid item xs={12}>
            {sep.length === 0 && (
              <Typography variant="body2">Data Kosong</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            {msg && <Typography variant="body2">{msg}</Typography>}
          </Grid>
          {sep.map((item, index) => {
            return (
              <Grid item xs={12} key={"sep_" + index}>
                <Paper sx={{ padding: "8px" }}>
                  <Grid container>
                    <Grid item xs={12} container>
                      <Grid item xs={6}>
                        <Typography variant="body2">Pasien</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">PPK</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {item.namaPeserta}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {item.ppkPelayanan}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid item xs={6}>
                        <Typography variant="body2">No SEP</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="body2">Tgl SEP</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {item.noSep}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          {item.tglSep}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        onClick={() => handleCreate(item.noSep)}
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            );
          })}
          <Grid item xs={12}></Grid>
        </Grid>
      </Container>
    </>
  );
}

const mapStateToProps = (state) => ({
  stateAppointment: state.register.appointment,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actSetAppointment: (body) => dispatch(setAppointment(body)),
    actResetAppointment: (body) => dispatch(resetAppointment(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateControlPage);

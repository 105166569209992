import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  token: "",
  grossAmount: 0,
  statusTransaksi: "",
};

const setToken = (state, action) => {
  return updateObject(state, {
    token: action.data,
  });
};
const setGrossAmount = (state, action) => {
  return updateObject(state, {
    grossAmount: action.data,
  });
};
const setStatusTransaksi = (state, action) => {
  return updateObject(state, {
    statusTransaksi: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MT_TOKEN:
      return setToken(state, action);
    case actionTypes.SET_MT_GROSS_AMOUNT:
      return setGrossAmount(state, action);
      case actionTypes.SET_MT_STATUS_TRX:
      return setStatusTransaksi(state, action);
    default:
      return state;
  }
};

export default reducer;

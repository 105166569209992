export const APP_LOADING = "APP_LOADING";
export const SET_AUTH = "SET_AUTH";
export const SET_BAHASA = "SET_BAHASA";
export const SET_BAHASA_OPEN = "SET_BAHASA_OPEN";
export const RESET_NOTIF = "RESET_NOTIF";
export const SET_NOTIF = "SET_NOTIF";
export const SET_SNACK_BAR = "SET_SNACK_BAR";
export const RESET_SNACK_BAR = "RESET_SNACK_BAR";

//MASTER
export const SET_PEKERJAAN = "SET_PEKERJAAN";
export const SET_AGAMA = "SET_AGAMA";
export const SET_PENDIDIKAN = "SET_PENDIDIKAN";
export const SET_STATUS_NIKAH = "SET_STATUS_NIKAH";
export const SET_JENIS_ID = "SET_JENIS_ID";
export const SET_KAMAR = "SET_KAMAR";
export const SET_PRODUK = "SET_PRODUK";
export const SET_LIST_DOCTOR = "SET_LIST_DOCTOR";
export const SET_LIST_POLI = "SET_LIST_POLI";
export const SET_DETAIL_DOCTOR = "SET_DETAIL_DOCTOR";
export const SET_PROPINSI = "SET_PROPINSI";
export const SET_KOTAMADYA = "SET_KOTAMADYA";
export const SET_KECAMATAN = "SET_KECAMATAN";
export const SET_KELURAHAN = "SET_KELURAHAN";
export const SET_JADWAL_DOKTER = "SET_JADWAL_DOKTER";
export const SET_DOKTER_KOAS = "SET_DOKTER_KOAS";
export const SET_PAKET_MCU = "SET_PAKET_MCU";
export const SET_LIST_PENANGGUNG = "SET_LIST_PENANGGUNG";

//AUTH
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const SET_IDENTITAS = "SET_IDENTITAS";
export const RESET_IDENTITAS = "RESET_IDENTITAS";
export const SET_IDENTITAS_AUTO = "SET_IDENTITAS_AUTO";
export const SET_IDENTITAS_SCAN_KTP = "SET_IDENTITAS_SCAN_KTP";
export const LOGOUT = "LOGOUT";
export const UPDATE_USER = "UPDATE_USER";

//REGISTER
export const SET_VAKSIN = "SET_VAKSIN";
export const RESET_VAKSIN = "RESET_VAKSIN";
export const SET_APPOINTMENT = "SET_APPOINTMENT";
export const RESET_APPOINTMENT = "RESET_APPOINTMENT";
export const SET_HISTORY_APPOINTMENT = "SET_HISTORY_APPOINTMENT";
export const SET_HISTORY_APPOINTMENT_DETAIL = "SET_HISTORY_APPOINTMENT_DETAIL";
export const SET_RUJUKAN_LIST = "SET_RUJUKAN_LIST";
export const SET_RUJUKAN = "SET_RUJUKAN";
export const SET_TIPE_APPOINTMENT = "SET_TIPE_APPOINTMENT";

//MIDTRANS
export const SET_MT_TOKEN = "SET_MT_TOKEN";
export const SET_MT_GROSS_AMOUNT = "SET_MT_GROSS_AMOUNT";
export const SET_MT_STATUS_TRX = "SET_MT_STATUS_TRX";

//KELAHIRAN
export const SET_KELAHIRAN_LIST = "SET_KELAHIRAN_LIST";
export const SET_LATEST_REQ_SKL = "SET_LATEST_REQ_SKL";
export const SET_IGD = "SET_IGD";
//KEBUGARAN
export const SET_KEBUGARAN_KARYAWAN = "SET_KEBUGARAN_KARYAWAN";
export const SET_KEBUGARAN_HASIL = "SET_KEBUGARAN_HASIL";
export const SET_KEBUGARAN_RESET = "SET_KEBUGARAN_RESET";
export const SET_KEBUGARAN_EVALUASI = "SET_KEBUGARAN_EVALUASI";
export const SET_KEBUGARAN_MULAI = "SET_KEBUGARAN_MULAI";
export const SET_KEBUGARAN_STOP = "SET_KEBUGARAN_STOP";
export const SET_KEBUGARAN_TIME = "SET_KEBUGARAN_TIME";
export const SET_KEBUGARAN_HISTORY = "SET_KEBUGARAN_HISTORY";
export const SET_KEBUGARAN_FAILED = "SET_KEBUGARAN_FAILED";

//IMT
export const SET_HASIL_IMT = "SET_HASIL_IMT";
export const SET_VARIABLE_IMT = "SET_VARIABLE_IMT";
export const RESET_IMT = "RESET_IMT";

//AKU BICARA
export const SET_LIST_PERASAANKU = "SET_LIST_PERASAANKU";
export const POST_LATIHANKU = "POST_LATIHANKU";


import React from "react";
import TopNav from "../../components/TopNav";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {
  cariRujukan,
  setAppointment,
  setRujukan,
  setRujukanList,
  setTipeAppointment,
} from "../../redux/actions";
import ModalNotification from "../../components/ModalNotification";
import failedImg from "../../assets/hand_fail.png";
import { getDiffDay } from "../../helpers/common/utility";

const RujukanCard = ({ nama, nokun, tgl, poli, handlePilih }) => {
  const isDisabled = getDiffDay(tgl) > 89 ? true : false;
  return (
    <Paper
      elevation={2}
      sx={{
        padding: "8px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant="body1">Nama</Typography>
            <Typography variant="subtitle2">{nama}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Nomer Rujukan</Typography>
            <Typography variant="subtitle2">{nokun}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={6}>
            <Typography variant="body1">Poli</Typography>
            <Typography variant="subtitle2">{poli}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Tgl Kunjungan</Typography>
            <Typography variant="subtitle2">{tgl}</Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={!isDisabled && handlePilih}
            disabled={isDisabled}
          >
            {isDisabled ? "Kadaluarsa" : "Pilih"}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

const BPJSFormPage = (props) => {
  const {
    stateBahasa,
    actCariRujukan,
    stateRujukanList,
    actSetAppointment,
    stateAppointment,
    actSetRujukanList,
    actSetRujukan,
    stateTipeAppointment,
    actSetTipeAppointment
  } = props;
  const navigate = useNavigate();
  const dataBPJS = [
    {
      id: 1,
      jenis: "Faskes Tingkat Pertama",
    },
    {
      id: 4,
      jenis: "Antar RS",
    },
    {
      id: 2,
      jenis: "Rujukan Internal - Beda Poli",
    },
  ];
  const [openFail, setOpenFail] = React.useState({
    open: false,
    message: "",
  });

  const [info, setInfo] = React.useState(true);

  React.useEffect(() => {
    actSetRujukanList("");
  }, [actSetRujukanList]);
  React.useEffect(() => {
    actSetAppointment({
      jenis_penanggung: 1,
    });
    actSetTipeAppointment("register_online");
    localStorage.setItem("user", JSON.stringify({
      mr: "000000",
      nama: "ADMIN",
      tgl_lahir: "1949-11-07",
      no_telp: "",
      email: "admin@admin.com",
    }));
  }, []);
  const handleFail = (msg) => {
    setOpenFail({
      open: true,
      message: msg,
    });
  };

  const handleCari = () => {
    actCariRujukan(
      {
        no_kartu: stateAppointment.no_kartu,
        jenis_kunjungan: stateAppointment.jenis_kunjungan,
      },
      {
        failed: (msg) => handleFail(msg),
      }
    );
  };
  const handlePilih = ({ kd_poli, no_rujukan, no_kartu, nik, nama_poli }) => {
    stateTipeAppointment === "register_online" &&
      actSetAppointment({
        layanan: {
          id_poli: "",
          ref_layanan_nama: "",
          disabled: false,
        },
        dokter: {
          peg_id: "",
          nama_dokter: "",
          disabled: false,
        },
      });

    actSetAppointment({
      no_kartu: no_kartu,
      nik: nik,
      no_rujukan: no_rujukan,
    });
    actSetRujukan({
      no_rujukan: no_rujukan,
      nik: nik,
      no_kartu: no_kartu,
      kd_poli: kd_poli,
      nama_poli: nama_poli,
    });
    navigate("/make-appointment");
  };
  return (
    <>
      <ModalNotification
        open={openFail.open}
        setOpen={(open) => setOpenFail({ ...openFail, open: open })}
        title={"Failed"}
        subtitle={openFail.message}
        img={failedImg}
      />
      <ModalNotification
        open={info}
        setOpen={(open) => setInfo(open)}
        title="Informasi Penting !"
        subtitle="Untuk Melakukan Kontrol Mohon Datang Ke KIOS Pendaftaran Lobby Lt.1"
      />
      <Container
        sx={{
          paddingTop: "16px",
        }}
      >
        <Stack spacing={2}>
          <TopNav
            type="back"
            title={stateBahasa === "indonesia" ? "Rujukan" : "Refferance"}
            click={() => {
              navigate(-1);
            }}
          />
          {/* <FormControl
            fullWidth
            required
            error={!stateAppointment.jenis_kunjungan}
          >
            <InputLabel id="jenis">Jenis Rujukan</InputLabel>
            <Select
              labelId="jenis"
              id="jenis"
              name="jenis"
              value={stateAppointment.jenis_kunjungan}
              onChange={(e) => {
                actSetAppointment({
                  jenis_kunjungan: e.target.value,
                });
              }}
            >
              {dataBPJS.map((item) => {
                return (
                  <MenuItem
                    key={"jenisID" + item.id}
                    value={item.id.toString()}
                  >
                    {item.jenis}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl> */}
          <TextField
            label="Nomor Kartu Pasien"
            fullWidth
            value={stateAppointment.no_mr}
            error={!stateAppointment.no_mr}
            // // disabled={!stateAppointment.jenis_kunjungan}
            onChange={(e) =>
              actSetAppointment({
                no_mr: e.target.value,
              })
            }
          />
          <TextField
            label="Nomor HP"
            fullWidth
            value={stateAppointment.no_telp}
            error={!stateAppointment.no_telp}
            // // disabled={!stateAppointment.jenis_kunjungan}
            onChange={(e) =>
              actSetAppointment({
                no_telp: e.target.value,
              })
            }
          />
          <TextField
            label="Nomor Kartu BPJS"
            fullWidth
            value={stateAppointment.no_kartu}
            error={!stateAppointment.no_kartu}
            // disabled={!stateAppointment.jenis_kunjungan}
            onChange={(e) =>
              actSetAppointment({
                no_kartu: e.target.value,
              })
            }
          />
          {(!stateAppointment.no_kartu ||
            !stateAppointment.no_mr ||
            !stateAppointment.no_telp) && (
            <Typography variant="body1" color="red">
              Mohon isi semua parameter
            </Typography>
          )}
          <Button
            fullWidth
            variant="contained"
            disabled={
              !stateAppointment.no_kartu ||
              !stateAppointment.no_mr ||
              !stateAppointment.no_telp
              //  || !stateAppointment.jenis_kunjungan
            }
            onClick={handleCari}
          >
            Cari
          </Button>
          <Typography variant="subtitle2">Pilih Rujukan</Typography>
          {!stateRujukanList && (
            <Typography variant="body1">Tidak ada rujukan</Typography>
          )}
          {stateRujukanList &&
            stateRujukanList.map((item, index) => {
              return (
                <RujukanCard
                  key={"rujukan_" + index}
                  nama={item.peserta.nama}
                  poli={item.poliRujukan.nama}
                  nokun={item.noKunjungan}
                  tgl={item.tglKunjungan}
                  handlePilih={() =>
                    handlePilih({
                      kd_poli: item.poliRujukan.kode,
                      no_rujukan: item.noKunjungan,
                      nik: item.peserta.nik,
                      no_kartu: item.peserta.noKartu,
                      nama_poli: item.poliRujukan.nama,
                    })
                  }
                />
              );
            })}
        </Stack>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({
  stateBahasa: state.app.bahasa,
  stateRujukanList: state.register.rujukanList,
  stateAppointment: state.register.appointment,
  stateTipeAppointment: state.register.tipe_appointment,
});

const mapDispatchToProps = (dispatch) => {
  return {
    actCariRujukan: (body, action) => dispatch(cariRujukan(body, action)),
    actSetAppointment: (body) => dispatch(setAppointment(body)),
    actSetTipeAppointment: (body) => dispatch(setTipeAppointment(body)), 
    actSetRujukanList: (body) => dispatch(setRujukanList(body)),
    actSetRujukan: (body) => dispatch(setRujukan(body)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BPJSFormPage);

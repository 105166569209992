import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
// import Banner from "../../components/Banner";
import ProfileBar from "../../../components/ProfileBar";
import CardImg from "../../../components/CardImg";
import BottomNav from "../../../components/BottomNav";
import { useNavigate, useParams } from "react-router-dom";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
// import SwiperCore, { Pagination } from "swiper";
import pelayananImg from "../../../assets/pelayanan.png";
import rsudcPNG from "../../../assets/logo_new.png";
// import maintenancePng from "../../../assets/maintenance.png";
import DokterImg from "../../../assets/dokter.png";
import BedImg from "../../../assets/info_kamar.png";
import MCUImg from "../../../assets/mcu.png";
import eRlanImg from "../../../assets/e_rlan.jpeg";
import PhysicalImg from "../../../assets/physical-therapy.png";
import PopImg from "../../../assets/popup-aplikasi-min.png";
import handCall from "../../../assets/hand_call.png";
import BabyImg from "../../../assets/baby.png";
import vaksinImg from "../../../assets/vaksin.png";
import AkuBicaraImg from "../../../assets/akubicara.jpeg";
import { connect } from "react-redux";
// import TranslateIcon from "@mui/icons-material/Translate";
import HelpIcon from "@mui/icons-material/HelpOutlined";
import { NTRL_000, NTRL_100 } from "../../../theme/colors";
import {
  getHistoryAppointment,
  getPaketMCU,
  resetAppointment,
  setAppointment,
  setBahasaOpen,
  setTipeAppointment,
  getListAkuBicara
} from "../../../redux/actions";
import { formatNumber } from "../../../helpers/common/utility";
import DialogBahasa from "../../../components/DialogBahasa";
import Box from "@mui/material/Box";
import CardPaket from "../../../components/CardPaket";
import ModalNotification from "../../../components/ModalNotification";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import HistoryIcon from "@mui/icons-material/History";
import LogoutIcon from "@mui/icons-material/Logout";
import LanguageIcon from "@mui/icons-material/Language";
import HistoryCard from "../../../components/HistoryCard";
// import axios from "axios";
import MidtransSnap from "../../../components/MidtransSnap";
import { generateBillingMT } from "../../../redux/actions/midtrans";
import AccountBox from "@mui/icons-material/AccountBox";
import BannerOne from "../../../assets/banner/1.png";
import BannerTwo from "../../../assets/banner/2.png";
// import BannerThree from "../../assets/banner/3.png";
import BannerFour from "../../../assets/banner/4.png";
import BannerFive from "../../../assets/banner/5.png";
import { Button, Dialog } from "@mui/material";
import { Close } from "@mui/icons-material";
import moment from "moment";
import { findMaintenance } from "../../../redux/actions/master";
import TopNav from "../../../components/TopNav";
import AudioPlayer from "../../../components/Audio";
import TextToSpeech from "../../../components/TextToSpeech";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
const labels = ["30/10", "31/10", "01/11", "02/11", "03/11", "04/11"];


const drawerItems = [
  {
    icon: <LockOpenIcon fontSize="small" />,
    title: {
      idn: "Login / Register",
      eng: "Login / Register",
    },
    action: "/login",
  },
  {
    icon: <HelpIcon fontSize="small" />,
    title: {
      idn: "Bantuan",
      eng: "Help",
    },
    action: "/faq",
  },
  {
    icon: <HelpIcon fontSize="small" />,
    title: {
      idn: "KIOSK",
      eng: "KIOSK",
    },
    action: "/splash-kios",
  },
];

const drawerItemsAuth = [
  {
    icon: <AccountBox fontSize="small" />,
    title: {
      idn: "Profile",
      eng: "Profile",
    },
    action: "/profile",
  },
  {
    icon: <HistoryIcon fontSize="small" />,
    title: {
      idn: "Riwayat",
      eng: "History",
    },
    action: "/history",
  },
  {
    icon: <HelpIcon fontSize="small" />,
    title: {
      idn: "Bantuan",
      eng: "Help",
    },
    action: "/faq",
  },
  {
    icon: <LogoutIcon fontSize="small" />,
    title: {
      idn: "Logout",
      eng: "Logout",
    },
    action: "/logout",
  },
];

const useStyles = makeStyles(() => ({
  textContent: {
    padding: "0 8px !important",
    flexGrow: 100,
  },
  filterContent: {
    flexGrow: 100,
  },
  searchBar: {
    display: "flex",
  },
  root: {
    paddingTop: "16px",
    paddingBottom: "30vh",
    minHeight: "100vh",
    backgroundColor: NTRL_100,
  },
  iconBahasa: {
    position: "absolute",
    bottom: "15vh",
    right: "8px",
    zIndex: "1000",
    color: "#FFF !important",
    // backgroundColor: PRI_300,
    // borderRadius: "50%",
  },
  rsudLogo: {
    objectFit: "contain",
    maxHeight: "40px",
    width: "auto",
  },
}));

const PerkembangankuPage = (props) => {
  const classes = useStyles();
  const {
    stateBahasa,
    actSetBahasaOpen,
    stateUser,
    actResetAppointment,
    actGetPaketMCU,
    statePaket,
    stateUserUmur,
    actSetAppointment,
    actSetTipeAppointment,
    actGetHistoryAppointment,
    stateHistory,
    actGenerateBillingMT,
    actFindMaintenance,
    stateListData,
  } = props;

  const [utterance, setUtterance] = useState(null);

  const navigate = useNavigate();

  const { id } = useParams();

  const [ dataloop,setData ] = useState([]);

  const [ dataloopModal,setDataModal ] = useState([]);

  const loppdata = (jumlah) => {
    let rr = [];
    for (let i = 1; i < (jumlah + 1); i++) {

        rr.push({ id: i, high: (i*3-1), low: (i*3-3)});
    }

    setData(rr);
    
    // console.log(dataloop);
  }

  React.useEffect(() => {

    actGetPaketMCU({ typee: id }
        ,        
        (val) => {

            console.log(val.length / 3);

            loppdata(val.length / 3);

        }
    );
    // loppdata(statePaket.length / 3);
  }, []);

  const [helpDialog, setHelpDialog] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [modalNotif, setModalNotif] = React.useState(false);
  const [modalNotifAkuBicara, setModalNotifAkuBicara] = React.useState(false);
  const [mtToken, setMtToken] = React.useState("");
//   const [mtce, setMtce] = React.useState({
//     title: "",
//     subtitle: "",
//     open: false,
//   });

  const contentSlide = [
    {
      title: stateBahasa === "indonesia" ? "Daftar Online" : "Registration",
      click: () => {
        actResetAppointment();
        actSetTipeAppointment("register_online");
        navigate("/choose-assurance");
      },
      // disabled: moment().diff(moment("2023-07-01")) > 1,
      img: pelayananImg,
    },
    {
      title: stateBahasa === "indonesia" ? "Dokter" : "Doctor",
      click: () => navigate("/doctor-home"),
      disabled: false,
      img: DokterImg,
    },
    {
      title: stateBahasa === "indonesia" ? "Info Kamar" : "Room Info",
      click: () => navigate("/rooms"),
      disabled: false,
      img: BedImg,
    },
    {
      title: "MCU",
      click: () => navigate("/mcu"),
      disabled: false,
      img: MCUImg,
    },
  ];

  const handlePayment = (rkun_id, id_layanan, token, order_id) => {
    actGenerateBillingMT(
      {
        rkun_id: rkun_id,
        id_layanan: id_layanan,
        token,
        order_id,
      },
      (val) => navigate(val)
    );
  };
  const handleBack = () => {
    navigate(-1);
  };
//   React.useEffect(() => {
//     actFindMaintenance((body) => {
//       console.log(body);
//       setMtce({
//         title: body.title,
//         subtitle: body.subtitle,
//         open: true,
//       });
//     });
//   }, []);

  React.useEffect(() => {
    if (stateUser) {
      actGetHistoryAppointment(
        {
          filter: "today",
          is_today: true,
        },
        () => navigate("/login", { replace: true })
      );
    }
  }, [actGetHistoryAppointment, navigate, stateUser]);

//   const labels = Utils.months({count: 7});
    const data = {
    labels: labels,
    datasets: [{
        label: 'My First Dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
    }]
    };

  

  

//   React.useEffect(() => {
//     loppdata(statePaket.length / 3);
//   },[]);

  return (
    <div className={classes.root}>
      <Dialog
        open={modalNotif}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Box alignSelf="flex-end">
          <IconButton
            style={{
              backgroundColor: "#FFF",
            }}
            onClick={() => setModalNotif(false)}
          >
            <Close />
          </IconButton>
        </Box>
        <img src={PopImg} alt="" />
      </Dialog>
      <Dialog
        open={modalNotifAkuBicara}
            PaperProps={{
            style: {
                backgroundColor: "transparent",
                boxShadow: "none",
            },
            }}
        >
        <Box alignSelf="flex-end">
          <IconButton
            style={{
              backgroundColor: "#FFF",
            }}
            onClick={() => {
                setModalNotifAkuBicara(false);
                setDataModal([]);
            }}
            >
            <Close />
          </IconButton>
        </Box>
        {/* <img src={PopImg} alt="" /> */}
                {/* <AudioPlayer src={dataloopModal.length > 0 ? dataloopModal[0]['audio'] : ''} play={true}
                /> */}
                
              <Container>
                  <Grid container spacing={3}>
                      {/* {statePaket.map((item, index) => {

                          if (index >= artist.low && index <= artist.high) {
                              console.log(item.url); */}
                              
                                  <Grid
                                      item
                                      container
                                      justifyContent="center"
                                      xs={12}
                                      key={"category_k"}
                                  >
                                      <CardImg
                                          variant="outlined"
                                          type="akubicara"
                                          title={dataloopModal.length > 0 ? dataloopModal[0]['nama'] : ''}
                                          text=""
                                          img={dataloopModal.length > 0 ? dataloopModal[0]['url'] : ''}
                                          direction="column"
                                          typee="aplikasi"
                                          hImg={300}
                                          click={() => {
                                            
                                          }}
                                      />
                                  </Grid>
                              
                          {/* }
                      })} */}
                  </Grid>
              </Container>
              {/* <TextToSpeech text={dataloopModal.length > 0 ? dataloopModal[0]['nama'] : ''} playis={dataloopModal.length > 0 ? dataloopModal[0]['isaudio'] : false} /> */}
      </Dialog>
      <MidtransSnap />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(!openDrawer)}
      >
        <Box
          sx={{ width: "64vw" }}
          role="presentation"
          // onClick={() => setOpenDrawer(!openDrawer)}
          // onKeyDown={() => setOpenDrawer(!openDrawer)}
        >
          <Container
            sx={{
              padding: "16px 0",
            }}
          >
            <Stack
              spacing={2}
              justifyContent="center"
              display="flex"
              alignItems="center"
            >
              <ProfileBar type="big" />
              <Typography variant="body1" align="center">
                {stateUser.nama}
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  maxWidth: 360,
                  bgcolor: "background.paper",
                }}
              >
                <List>
                  {stateUser &&
                    drawerItemsAuth.map((item, index) => {
                      return (
                        <>
                          <ListItem disablePadding key={"drawerItem_" + index}>
                            <ListItemButton
                              onClick={() => navigate(item.action)}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText
                                primary={
                                  stateBahasa === "indonesia"
                                    ? item.title.idn
                                    : item.title.eng
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
                  {!stateUser &&
                    drawerItems.map((item, index) => {
                      return (
                        <>
                          <ListItem disablePadding key={"drawerItem2_" + index}>
                            <ListItemButton
                              onClick={() => navigate(item.action)}
                            >
                              <ListItemIcon>{item.icon}</ListItemIcon>
                              <ListItemText
                                primary={
                                  stateBahasa === "indonesia"
                                    ? item.title.idn
                                    : item.title.eng
                                }
                              />
                            </ListItemButton>
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => actSetBahasaOpen(true)}>
                      <ListItemIcon>
                        <LanguageIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          stateBahasa === "indonesia" ? "Bahasa" : "Language"
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Stack>
            <Box
              position="absolute"
              bottom="16px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="100%"
              onClick={() => navigate("/kelahiran")}
            >
              <Typography variant="body1" align="center">
                &copy;SIM RSUD Cengkareng
              </Typography>
            </Box>
          </Container>
        </Box>
      </Drawer>
      <ModalNotification
        open={helpDialog}
        setOpen={setHelpDialog}
        title={stateBahasa === "indonesia" ? "Butuh Bantuan?" : "Need Help?"}
        subtitle={""}
        img={handCall}
        actContinue={() => navigate("/faq")}
        continueText="Yes"
        abortText="No"
        actAbort={() => setHelpDialog(false)}
      />
      {/* <ModalNotification
        open={mtce.open}
        setOpen={(body) =>
          setMtce({
            ...mtce,
            open: false,
          })
        }
        title={mtce.title}
        subtitle={mtce.subtitle}
        img={maintenancePng}
        // actContinue={() => navigate("/faq")}
        // continueText="Mengerti"
        // abortText="No"
        // actAbort={() => setHelpDialog(false)}
      /> */}
      <ModalNotification
        open={
          stateUser && (!stateUser.no_telp || !stateUser.email) ? true : false
        }
        setOpen={() => navigate("/profile")}
        title={
          stateBahasa === "indonesia"
            ? "Please Complete Your Email and Phone"
            : "Mohon Lengkapi Email, dan HP anda"
        }
        subtitle={""}
        img={handCall}
        abortText="Profile"
        actAbort={() => navigate("/profile")}
      />
      <DialogBahasa />
      {/* <div className={classes.iconBahasa}>
        <Box display="flex" flexDirection="column">
          <IconButton
            onClick={() => actSetBahasaOpen(true)}
            sx={{
              backgroundColor: PRI_300,
              borderRadius: "50%",
            }}
          >
            <TranslateIcon fontSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => setHelpDialog(true)}
            sx={{
              marginTop: "8px",
              backgroundColor: PRI_300,
              borderRadius: "50%",
            }}
          >
            <HelpIcon fontSize="medium" />
          </IconButton>
        </Box>
      </div> */}
      

      <Stack spacing={2}>
        {/* <Container>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Box onClick={() => navigate("/")}>
                <img src={rsudcPNG} className={classes.rsudLogo} alt="" />
              </Box>
            </Grid>
            <Grid item>
              <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container> */}
        <TopNav
            title={stateBahasa === "indonesia" ? "PERKEMBANGANKU" : ""}
            subtitle={
            stateBahasa === "indonesia"
                ? "GRAFIK PERKEMBANGANKU"
                : "PERKEMBANGANKU"
            }
            variant="with-background"
            click={handleBack}
        />
        
        <Box
          sx={{
            backgroundColor: NTRL_000,
            paddingTop: "px",
            paddingBottom: "32px",
          }}
        >
          {/* <Container>
            <Typography variant="subtitle2">
              {stateBahasa === "indonesia" ? "Promosi" : "Promotion"}
            </Typography>
          </Container> */}
          <div>
            <Line data={data} />
          </div>
        </Box>
        <Box>
          <Container>
            {stateHistory &&
              stateHistory.map((item, index) => {
                if (index === 0) {
                  return (
                    <div key={"rsv_" + item.rkun_id}>
                      <HistoryCard
                        title={item.dokter.peg_nama}
                        subtitle={item.layanan.ref_layanan_nama}
                        tanggal={item.rkun_tgl_visit}
                        cancelled={item.rkun_batal}
                        reservasi={item.rkun_reservasi}
                        rkunId={item.rkun_id}
                        // onPayment={() =>
                        //   handlePayment(
                        //     item.rkun_id,
                        //     item.rkun_id_layanan,
                        //     item.midtrans.length > 0
                        //       ? item.midtrans[0].trx_mt_token
                        //       : null,
                        //     item.midtrans.length > 0
                        //       ? item.midtrans[0].trx_mt_order_id
                        //       : null
                        //   )
                        // }
                        isBPJS={item.is_bpjs}
                        paymentStatus={
                          item.midtrans.length > 0
                            ? item.midtrans[0].trx_mt_transaction_status
                            : "pending"
                        }
                        doctorId={item.rkun_id_dokter}
                        // oncancel={() =>
                        //   batalDialog({
                        //     open: true,
                        //     rkun_id: item.rkun_id,
                        //   })
                        // }
                      />
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
          </Container>
        </Box>

        {/* <div>
          <Container>
            <Grid container spacing={2}>
              {contentSlide.map((item, index) => {
                return (
                  <Grid
                    item
                    container
                    justifyContent="center"
                    xs={3}
                    key={"category_" + index}
                  >
                    <CardImg
                      variant="outlined"
                      type="small"
                      title={item.title}
                      text="Bedah Hati"
                      click={item.click}
                      disabled={item.disabled}
                      img={item.img}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Container>
        </div> */}
        
      </Stack>
      <BottomNav />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    stateBahasa: state.app.bahasa,
    stateUser: state.auth.user,
    stateUserUmur: state.auth.user_umur,
    statePaket: state.akubicara.listPerasaan,
    stateHistory: state.register.historyAppointment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSetBahasaOpen: (body) => dispatch(setBahasaOpen(body)),
    actResetAppointment: (body) => dispatch(resetAppointment(body)),
    actGetPaketMCU: (body,success) => dispatch(getListAkuBicara(body,success)),
    actSetAppointment: (body) => dispatch(setAppointment(body)),
    actSetTipeAppointment: (body) => dispatch(setTipeAppointment(body)),
    actGetHistoryAppointment: (body, action) =>
      dispatch(getHistoryAppointment(body, action)),
    actGenerateBillingMT: (body, navigator) =>
      dispatch(generateBillingMT(body, navigator)),
    // actFindMaintenance: (action) => dispatch(findMaintenance(action)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PerkembangankuPage);

import React from "react";
import { Routes, Route } from "react-router-dom";
import BPJSFormPagePass from "./pages/BPJSFormPagePass";
import CreateControlPage from "./pages/CreateControlPage";
import RouteContainer from "./pages/RouteContainer";
import Perkembanganku from "./pages/AkuBicaraPage/Perkembanganku";
const PilihPenanggung = React.lazy(() => import("./pages/PilihPenanggung"));
const BPJSFormPage = React.lazy(() => import("./pages/BPJSFormPage"));
const DocterListPage = React.lazy(() => import("./pages/DocterListPage"));
const DetailDoctor = React.lazy(() => import("./pages/DetailDoctor"));
const Appointment = React.lazy(() => import("./pages/Appointment"));
const Payment = React.lazy(() => import("./pages/Payment"));
const OnBoardingPage = React.lazy(() => import("./pages/OnBoardingPage"));
const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const RegisterUserPage = React.lazy(() => import("./pages/RegisterUserPage"));
const RegisterPage = React.lazy(() => import("./pages/RegisterPage"));
const ExceptionPage = React.lazy(() => import("./pages/ExceptionPage"));
const ListKamar = React.lazy(() => import("./pages/ListKamar"));
const HomePage = React.lazy(() => import("./pages/HomePage"));
const RoomsPage = React.lazy(() => import("./pages/RoomsPage"));
const NotFoundPage = React.lazy(() => import("./pages/NotFoundPage"));
const RegisterVaksin = React.lazy(() => import("./pages/RegisterVaksin"));
const NewsPage = React.lazy(() => import("./pages/NewsPage"));
const MCUPage = React.lazy(() => import("./pages/MCUPage"));
const PoliListPage = React.lazy(() => import("./pages/PoliListPage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const HistoryPage = React.lazy(() => import("./pages/HistoryPage"));
const PaketDetailPage = React.lazy(() => import("./pages/PaketDetailPage"));
const FAQPage = React.lazy(() => import("./pages/FAQPage"));
const RegisterPlain = React.lazy(() => import("./pages/FAQPage/RegisterPlain"));
const BookingBPJS = React.lazy(() => import("./pages/FAQPage/BookingBPJS"));
const BookingUmum = React.lazy(() => import("./pages/FAQPage/BookingUmum"));
const LogoutPage = React.lazy(() => import("./pages/LogoutPage"));
const MidtransPage = React.lazy(() => import("./pages/MidtransPage"));
const AddKelahiranPage = React.lazy(() => import("./pages/AddKelahiranPage"));
const RockportResultPage = React.lazy(() =>
  import("./pages/RockportResultPage")
);
const RegisterPatient = React.lazy(() =>
  import("./pages/FAQPage/RegisterPatient")
);

const ForgotPasswordPage = React.lazy(() =>
  import("./pages/ForgotPasswordPage")
);
const ResetPasswordPage = React.lazy(() => import("./pages/ResetPasswordPage"));
const AppointmentDetailPage = React.lazy(() =>
  import("./pages/AppointmentDetailPage")
);
const AppointmentMCU = React.lazy(() => import("./pages/AppointmentMCU"));
const ApotekPage = React.lazy(() => import("./pages/ApotekPage"));
const AppointmentConfirmPage = React.lazy(() =>
  import("./pages/AppointmentConfirmPage")
);
const DocterKoasPage = React.lazy(() => import("./pages/DocterKoasPage"));
const PilihTanggalFull = React.lazy(() => import("./pages/PilihTanggalFull"));
const DoctorListFull = React.lazy(() => import("./pages/DoctorListFull"));
const SplashKios = React.lazy(() => import("./pages/SplashKios"));
const KelahiranPage = React.lazy(() => import("./pages/KelahiranPage"));
const RequestSKL = React.lazy(() => import("./pages/RequestSKL"));
const RegisterIGDPage = React.lazy(() => import("./pages/RegisterIGDPage"));
const LoginIGDPage = React.lazy(() => import("./pages/LoginIGDPage"));
const TestBugarPage = React.lazy(() => import("./pages/TestBugarPage"));
const RockportHistoryPage = React.lazy(() =>
  import("./pages/RockportHistoryPage")
);
const TestKebugaranAdminPage = React.lazy(() =>
  import("./pages/TestKebugaranAdminPage")
);
const IMTPage = React.lazy(() => import("./pages/IMTPage"));
const FisioPage = React.lazy(() => import("./pages/FisioPage"));
const PPPK = React.lazy(() => import("./pages/PPPK"));
const ScanKTPPage = React.lazy(() => import("./pages/ScanKTPPage"));
const RegisterKTPPage = React.lazy(() => import("./pages/RegisterKTPPage"));
const AkuBicaraPage = React.lazy(() => import("./pages/AkuBicaraPage"));
const PerasaankuPage = React.lazy(() => import("./pages/AkuBicaraPage/PerasaankuPage"));
const LatihankuPage = React.lazy(() => import("./pages/AkuBicaraPage/LatihankuPage"));
const EdukasiPage = React.lazy(() => import("./pages/AkuBicaraPage/EdukasiPage"));

const index = (props) => {
  return (
    <>
      <Routes>
        <Route exact path="/task-apotek" element={<ApotekPage />} />
        <Route
          exact
          path="/find-refferance-pass"
          element={<BPJSFormPagePass />}
        />
        <Route exact path="/create-control" element={<CreateControlPage />} />
        <Route exact path="/doctor-koas" element={<DocterKoasPage />} />
        <Route element={<RouteContainer />}>
          <Route exact path="/doctor-home" element={<DocterListPage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route
            exact
            path="/doctor-detail/:doctorId"
            element={<DetailDoctor />}
          />
          <Route exact path="/make-appointment" element={<Appointment />} />
          <Route exact path="/make-payment" element={<Payment />} />
          <Route
            exact
            path="/appointment-success/:id"
            element={<AppointmentDetailPage />}
          />
          <Route exact path="/on-boarding" element={<OnBoardingPage />} />
          <Route exact path="/choose-assurance" element={<PilihPenanggung />} />
          {/* <Route exact path="/find-refferance" element={<BPJSFormPage />} /> */}
          <Route exact path="/" element={<OnBoardingPage />} />
          <Route exact path="/login" element={<LoginPage />} />
          <Route exact path="/logout" element={<LogoutPage />} />
          <Route
            exact
            path="/forget-password"
            element={<ForgotPasswordPage />}
          />
          <Route exact path="/pppk" element={<PPPK />} />
          <Route exact path="/register" element={<RegisterPage />} />
          <Route exact path="/register-igd" element={<RegisterIGDPage />} />
          <Route exact path="/login-igd" element={<LoginIGDPage />} />
          <Route exact path="/register-user" element={<RegisterUserPage />} />
          <Route exact path="/list-room" element={<ListKamar />} />
          <Route exact path="/list-poli" element={<PoliListPage />} />
          <Route exact path="/pick-date" element={<PilihTanggalFull />} />
          <Route exact path="/splash-kios" element={<SplashKios />} />
          <Route
            exact
            path="/doctor-schedule-full"
            element={<DoctorListFull />}
          />
          <Route exact path="/rooms" element={<RoomsPage />} />
          <Route exact path="/mcu" element={<MCUPage />} />
          <Route exact path="/reset-password" element={<ResetPasswordPage />} />
          <Route
            exact
            path="/package/detail/:id"
            element={<PaketDetailPage />}
          />
          <Route exact path="/register-vaksin" element={<RegisterVaksin />} />
          <Route exact path="/news-page" element={<NewsPage />} />
          <Route exact path="/profile" element={<ProfilePage />} />
          <Route exact path="/history" element={<HistoryPage />} />
          <Route exact path="/appointment-mcu" element={<AppointmentMCU />} />

          <Route exact path="/faq" element={<FAQPage />} />
          <Route exact path="/faq/register-plain" element={<RegisterPlain />} />
          <Route
            exact
            path="/faq/register-account"
            element={<RegisterPatient />}
          />
          <Route exact path="/faq/appointment-bpjs" element={<BookingBPJS />} />
          <Route exact path="/faq/appointment-umum" element={<BookingUmum />} />
          <Route exact path="/midtrans/:order_id" element={<MidtransPage />} />
          <Route
            exact
            path="/appointment-confirm"
            element={<AppointmentConfirmPage />}
          />
          <Route exact path="/kelahiran" element={<KelahiranPage />} />
          <Route
            exact
            path="/kelahiran/add/:kelahiranid"
            element={<AddKelahiranPage />}
          />
          <Route
            exact
            path="/request-skl/:mr_bayi/:kelahiran_id"
            element={<RequestSKL />}
          />
          <Route exact path="/rockport" element={<TestBugarPage />} />
          <Route
            exact
            path="/rockport/hasil"
            element={<RockportResultPage />}
          />
          <Route
            exact
            path="/rockport/history"
            element={<RockportHistoryPage />}
          />
          <Route
            exact
            path="/test-kebugaran-admin"
            element={<TestKebugaranAdminPage />}
          />
          <Route exact path="/imt" element={<IMTPage />} />
          <Route exact path="/fisiotherapy" element={<FisioPage />} />
          <Route exact path="/akubicara-latihanku/:id" element={<LatihankuPage />} />
          <Route exact path="/akubicara-edukasi/:id" element={<EdukasiPage />} />
          <Route exact path="/akubicara-perkembanganku" element={<Perkembanganku />} />
          
        </Route>
        <Route exact path="/exception-handling" element={<ExceptionPage />} />
        <Route exact path="*" element={<NotFoundPage />} />
        <Route exact path="/scan-ktp" element={<ScanKTPPage />} />
        <Route exact path="/register-ktp" element={<RegisterKTPPage />} />
        <Route exact path="/akubicara" element={<AkuBicaraPage />} />
        <Route exact path="/akubicara-perasaanku/:id" element={<PerasaankuPage />} />
        
        
        
         
      </Routes>
    </>
  );
};

export default index;

import React from "react";
import { connect } from "react-redux";
import {
  BASE_URL_MIDTRANS,
  CLIENT_KEY_MIDTRANS,
} from "../../helpers/common/config";
import SnackBarCustom from "../SnackBarCustom";

export const MidtransSnap = (props) => {
  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = `${BASE_URL_MIDTRANS}/snap/snap.js`;
    script.setAttribute("data-client-key", CLIENT_KEY_MIDTRANS);
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <SnackBarCustom />
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(MidtransSnap);

import { setError } from "../../redux/actions";
import moment from "moment";
export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

export const formatNumber = (num) => {
  return "IDR " + num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const stripMR = (val) => {
  let arr = val.split("");
  let text = "";
  arr.map((item, index) => {
    if (index > 0 && index % 2 === 0) {
      text += "-";
    }
    return (text += item);
  });
  return text;
};

export const parseToString = (val) => {
  return Number.isInteger(val) ? val.toString() : val;
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isJSON = (payload) => {
  try {
    let o = JSON.parse(payload);
    if (o && typeof o === "object") {
      return true;
    }
  } catch (error) {
    return false;
  }
};

export const errorHandler = (dispatch, err) => {
  if (err.toString().includes("Network Error")) {
    dispatch(setError("connection"));
  } else if (err.response.status === 401) {
    dispatch(setError("not-authenticated"));
  } else {
    dispatch(setError("system"));
  }
};

export const getDiffDay = (date) => {
  let now = moment(moment().format("YYYY-MM-DD"));
  let end = moment(date, "YYYY-MM-DD");
  let duration = moment.duration(now.diff(end));
  return duration.asDays();
};

export const getDaysBetweenDates = (startDate, endDate) => {
  startDate = moment(startDate);
  endDate = moment(endDate);
  let now = startDate.clone();
  let dates = [];

  while (now.isSameOrBefore(endDate)) {
    let month = now.format("MMMM");
    // if (dates.length > 0) {
    //   console.log(Object.keys(dates[0])[0])

    // }
    if (now.day() !== 0) {
      let found = null;
      if (dates.length > 0) {
        found = dates.find((e) => Object.keys(e)[0] === month);
      }
      if (found) {
        let key = Object.keys(found)[0];
        found[key].push({
          date: now.format("YYYY-MM-DD"),
          month: month,
          day: now.format("ddd"),
          day_number: now.format("DD"),
        });
      } else {
        dates.push({
          [month]: [
            {
              date: now.format("YYYY-MM-DD"),
              month: month,
              day: now.format("ddd"),
              day_number: now.format("DD"),
            },
          ],
        });
      }

      // dates.push({
      //   date: now.format("YYYY-MM-DD"),
      //   month: month,
      //   day: now.format("ddd"),
      //   day_number: now.format("DD"),
      // });
    }

    now.add(1, "days");
  }

  return dates;
};

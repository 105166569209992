export const APP_ENVIRONMENT = process.env.REACT_APP_ENV || "development";
export const BASE_URL = process.env.REACT_APP_SERVER || "http://localhost:4001";
export const BASE_URL_IMG =
  process.env.REACT_APP_IMAGE || "http://localhost:4001/public";
export const BASE_URL_MIDTRANS =
  process.env.REACT_APP_MIDTRANS_DEV === "production"
    ? process.env.REACT_APP_MIDTRANS_DEV
    : process.env.REACT_APP_MIDTRANS_DEV;

export const CLIENT_KEY_MIDTRANS =
  process.env.REACT_APP_MIDTRANS_CLIENT_KEY_DEV === "production"
    ? process.env.REACT_APP_MIDTRANS_CLIENT_KEY_DEV
    : process.env.REACT_APP_MIDTRANS_CLIENT_KEY_DEV;

export const BASE_URL_PAYMENT =
  process.env.REACT_APP_PAYMENT_DEV === "production"
    ? process.env.REACT_APP_PAYMENT_DEV
    : process.env.REACT_APP_PAYMENT_DEV;

import React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { PRI_500 } from "../../theme/colors";
import PropTypes from "prop-types";
import { formatNumber } from "../../helpers/common/utility";
import labImg from "../../assets/laboratorium.png";
import { Box } from "@mui/material";
import { connect } from "react-redux";

const useStyles = makeStyles({
  img: {
    height: "100px",
    width: "100%",
    objectFit: "contain",
  },
});
const CardPaket = (props) => {
  const {
    title,
    price,
    handleDetail,
    handleChoose,
    img,
    disabled,
    stateBahasa,
  } = props;
  const classes = useStyles();
  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          overflow: "hidden",
          padding: "8px",
        }}
      >
        <img
          src={img}
          alt=""
          className={classes.img}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = labImg;
          }}
        />
        <Grid container spacing={1} style={{ padding: "6px" }}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color={PRI_500}>
              {title}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid
              item
              xs={6}
              container
              justifyContent="center"
              direction="column"
            >
              <Typography variant="subtitle1" color={PRI_500}>
                {formatNumber(price)}
              </Typography>
              <Box onClick={handleDetail}>
                <Typography variant="body1" color="blue">
                  Detail
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6} container alignItems="center">
              <Button
                variant="contained"
                onClick={handleChoose}
                disabled={disabled}
              >
                {stateBahasa === "indonesia" ? "Pilih" : "Choose"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

CardPaket.propTypes = {
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  img: PropTypes.string,
  handleChoose: PropTypes.func,
  handleDetail: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    stateBahasa: state.app.bahasa,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CardPaket);

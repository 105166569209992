import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import doctorImg from "../../../assets/doctors.png";
import { TextField } from "@mui/material";

const useStyles = makeStyles((props) => ({
  img: {
    // width: "100%",
    // height: "auto",
    // height: "100px",
    objectFit: "cover",
    objectPosition: "center",
    // marginRight: "50px"
    // backgroundColor: "#000",
  },
  textareaa: {
    marginLeft: "50px",
  },
  root: {
    cursor: "pointer",
    padding: "12px",
  },
  textContent: {
    padding: "0 8px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const AkuBicaraCardImg = (props) => {
  const classes = useStyles();
  let html = "";
  let html2 = "";
  const {
    title = "Undefined",
    text = "Undefined",
    img,
    click = () => {},
    direction = "column",
    typee = "",
    hImg = 75,
    sizeText = "18px"
  } = props;
  switch (typee) {
        case "textarea":
            html2 = 
            // <TextField
            //     color="secondary"
            //     id="outlined-multiline-static"
            //     label="Targetku"
            //     multiline
            //     rows={4}
            //     defaultValue=""
            // />
            <TextField 
            label="Targetku"
            multiline
            rows={4} 
            color="secondary"
            // className={classes.textareaa}
            // style={classes.textareaa}
            sx={{marginLeft: "50px",}}
            focused  />

            break;
        case "aplikasi":
            html2 = 
            <Typography
                variant="body2"
                // noWrap
                // overflow="hidden"
                // textOverflow="ellipsis"
                width="40%"
                justifyContent={"left"} alignItems="left"
                sx={{fontSize: sizeText,marginLeft: "20px",textAlign: "left",justifyContent: "left"}}
            >
                {title}
            </Typography>

            break;
            
        default:
            html = <Typography
                variant="body2"
                // noWrap
                // overflow="hidden"
                // textOverflow="ellipsis"
                width="100%"
                justifyContent={"center"} alignItems="center"
                sx={{fontSize: sizeText,textAlign: "center",justifyContent: "center"}}
            >
                {title}
            </Typography>;
            break;

        // return <>{html}</>;
            
  }
  return (
    <Paper {...props} className={classes.root} onClick={click}>
      <Grid container direction={direction}>
        <Grid item container justifyContent={"center"} 
        alignItems="center"
        sx={{width: "100%"}}
        width={100}
        >
          <img
            className={classes.img}
            src={img}
            alt={title}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = doctorImg;
            }}
            height= {hImg}
          />
          {html2}
          {/* {html} */}
        </Grid>
        <Grid item justifyContent={"center"} alignItems="center">
          {html}
        </Grid>
        {/* <Grid item>
          <Typography variant="body2">{text}</Typography>
        </Grid> */}
      </Grid>
    </Paper>
  );
};

export default AkuBicaraCardImg;

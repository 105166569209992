import React from "react";
import Back from "./Back";
import WithBackground from "./WithBackground";
import WithProfile from "./WithProfile";
const TopNav = (props) => {
  let html = "";
  let { variant } = props;
  switch (variant) {
    case "with-background":
      html = <WithBackground {...props} />;
      break;
    case "with-profile":
      html = <WithProfile {...props} />;
      break;
    default:
      html = <Back {...props} />;
      break;
  }
  return <>{html}</>;
};

export default TopNav;

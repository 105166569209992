import React, { Suspense } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import Routes from "./Routes";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import { PRI_600 } from "./theme/colors";
import { connect } from "react-redux";
import ModalNotification from "./components/ModalNotification";
import { resetNotif } from "./redux/actions/app";
import handFailImg from "./assets/hand_fail.png";
import handSuccImg from "./assets/hand_succ.png";
import { onMessageListener, requestPermission } from "./firebase";

// const browserHistory = createBrowserHistory();
const useStyles = makeStyles(() => ({
  backdrop: {
    zIndex: 1305,
    color: "#fff",
  },
}));
const App = (props) => {
  const classes = useStyles();
  const { stateLoading, stateNotif, actResetNotif } = props;
  React.useEffect(() => {
    requestPermission();
    return () => {};
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ModalNotification
        open={stateNotif.open}
        setOpen={() => actResetNotif()}
        title={stateNotif.title}
        subtitle={stateNotif.subtitle}
        img={stateNotif.type === "error" ? handFailImg : handSuccImg}
        actAbort={stateNotif.actAbort}
      />
      <Backdrop className={classes.backdrop} open={stateLoading}>
        <center>
          <CircularProgress
            sx={{
              color: PRI_600 + "!important",
            }}
          />
        </center>
      </Backdrop>
      <BrowserRouter
      // basename="/web"
      >
        <Suspense
          fallback={
            <span
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                margin: "-25px 0 0 -25px",
              }}
            >
              <CircularProgress
                sx={{
                  color: PRI_600 + "!important",
                }}
              />
            </span>
          }
        >
          <Routes />
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    stateNotif: state.app.notification,
    stateLoading: state.app.loading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actResetNotif: () => dispatch(resetNotif()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  img: {
    width: "auto",
    height: "150px",
    borderRadius: "8px",
  },
  container: {
    padding: "16px 0",
  },
}));
export const ModalNotification = (props) => {
  const classes = useStyles();
  //   const [open, setOpen] = React.useState(false);
  const {
    open,
    title = "title",
    subtitle = "subtitle",
    abortText = "Close",
    continueText = "Submit",
    actAbort,
    actContinue,
    setOpen,
    img,
    noclose,
  } = props;
  const handleClose = () => {
    return setOpen(false);
  };
  const handleAbort = () => {
    return actAbort ? actAbort() : handleClose();
  };

  const ModalButton = () => {
    let html = "";
    if (actContinue) {
      html = (
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              onClick={actAbort ? handleAbort : handleClose}
            >
              {abortText}
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" onClick={() => actContinue()}>
              {continueText}
            </Button>
          </Grid>
        </Grid>
      );
    } else {
      html = (
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={actAbort ? handleAbort : handleClose}
            >
              {abortText}
            </Button>
          </Grid>
        </Grid>
      );
    }
    return <>{html}</>;
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={!noclose && handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Container className={classes.container}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent={"end"}>
              {!noclose && (
                <IconButton onClick={handleClose}>
                  <Close />
                </IconButton>
              )}
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              {img && <img className={classes.img} src={img} alt="" />}
            </Grid>
            <Grid item xs={12}>
              <Stack>
                <Typography variant="subtitle2" align="center">
                  {title}
                </Typography>
                <Typography variant="body1" align="center">
                  {subtitle}
                </Typography>
              </Stack>
            </Grid>
            <ModalButton />
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

ModalNotification.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  abortText: PropTypes.string,
  continueText: PropTypes.string,
  actAbort: PropTypes.func,
  actContinue: PropTypes.func,
  setOpen: PropTypes.func,
  img: PropTypes.string,
  noclose: PropTypes.bool,
};

export default ModalNotification;

import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  listPerasaan: []
};

const setLIST = (state, action) => {
  return updateObject(state, {
    listPerasaan: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    
    case actionTypes.SET_LIST_PERASAANKU:
      return setLIST(state, action);
    default:
      return state;
  }
};

export default reducer;

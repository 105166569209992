import TextField from "@mui/material/TextField";
import React from "react";
import NumberFormat from "react-number-format";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const PhoneNumberCustom = React.forwardRef(function PhoneNumberCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      format={"+## ####-####-####"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      //   maxLength={13}
      //   minLength={10}
    />
  );
});

const StopwatchCustom = React.forwardRef(function PhoneNumberCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format={"##:##:##"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      //   maxLength={13}
      //   minLength={10}
    />
  );
});
const StopwatchInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;

  //   const helperText = (
  //     <span className={classes.helperText}>{props.helperText}</span>
  //   );
  //   const errorText = (
  //     <span className={classes.helperText}>{props.errorText}</span>
  //   );
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: StopwatchCustom,
      }}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};
const PhoneNumberInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;

  //   const helperText = (
  //     <span className={classes.helperText}>{props.helperText}</span>
  //   );
  //   const errorText = (
  //     <span className={classes.helperText}>{props.errorText}</span>
  //   );
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: PhoneNumberCustom,
      }}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};

const NumberFormatMR = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format={"##-##-##"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      //   maxLength={13}
      //   minLength={10}
    />
  );
});
const NoMrInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;

  //   const helperText = (
  //     <span className={classes.helperText}>{props.helperText}</span>
  //   );
  //   const errorText = (
  //     <span className={classes.helperText}>{props.errorText}</span>
  //   );
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth}
      size={props.size}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: NumberFormatMR,
      }}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};

const NumberFormatKTP = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      format={"################"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      //   maxLength={13}
      //   minLength={10}
    />
  );
});
const NoKTPInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;

  //   const helperText = (
  //     <span className={classes.helperText}>{props.helperText}</span>
  //   );
  //   const errorText = (
  //     <span className={classes.helperText}>{props.errorText}</span>
  //   );
  return (
    <TextField
      fullWidth={props.fullWidth}
      size={props.size}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: NumberFormatKTP,
      }}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};
const NumberFormatBPJS = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      // format={"######"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={0}
      maxLength={13}
      //   minLength={10}
    />
  );
});

const NoBPJSInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;

  //   const helperText = (
  //     <span className={classes.helperText}>{props.helperText}</span>
  //   );
  //   const errorText = (
  //     <span className={classes.helperText}>{props.errorText}</span>
  //   );
  return (
    <TextField
      {...props}
      fullWidth={props.fullWidth}
      size={props.size}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      InputProps={{
        inputComponent: NumberFormatBPJS,
      }}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};

const DefaultInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;
  return (
    <TextField
      {...props}
      size={props.size}
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
    />
  );
};
const DecimalFormat = React.forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.formattedValue,
          },
        });
      }}
      // format={"######"}
      allowLeadingZeros
      allowNegative={false}
      decimalScale={3}
      minLength={0}
      // maxLength={props.max || 50}
    />
  );
});
const DecimalInput = (params) => {
  // eslint-disable-next-line no-unused-vars
  const { classes, props } = params;
  return (
    <TextField
      {...props}
      size={props.size}
      fullWidth={props.fullWidth}
      label={props.label}
      value={props.value}
      onChange={props.onChange}
      name={props.name}
      id={props.id}
      error={props.error}
      helperText={props.helperText}
      variant={props.variant || "outlined"}
      InputProps={{
        inputComponent: DecimalFormat,
      }}
    />
  );
};
const useStyles = makeStyles(() => ({}));

const CustomInput = (props) => {
  let input = null;
  const classes = useStyles();
  switch (props.tipe) {
    case "phone-number":
      input = PhoneNumberInput({ props, classes });
      break;
    case "no_mr":
      input = NoMrInput({ props, classes });
      break;
    case "no_bpjs":
      input = NoBPJSInput({ props, classes });
      break;
    case "ktp":
      input = NoKTPInput({ props, classes });
      break;
    case "stopwatch":
      input = StopwatchInput({ props, classes });
      break;
    case "decimal":
      input = DecimalInput({ props, classes });
      break;
    default:
      input = DefaultInput({ props, classes });
      break;
  }

  return <> {input} </>;
};

CustomInput.propTypes = {
  tipe: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  name: PropTypes.any.isRequired,
  id: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default CustomInput;

import auth from "./auth";
import master from "./master";
import app from "./app";
import register from "./register";
import midtrans from "./midtrans";
import kelahiran from "./kelahiran";
import kebugaran from "./kebugaran";
import akubicara from "./akubicara";
import imt from "./imt";
import { combineReducers } from "redux";

export default combineReducers({
  auth,
  master,
  app,
  register,
  midtrans,
  kebugaran,
  kelahiran,
  imt,
  akubicara
});

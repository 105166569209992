import React from 'react'
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ChevronLeftOutlined from "@mui/icons-material/ChevronLeftOutlined";
import makeStyles from "@mui/styles/makeStyles";
import {  NTRL_700, PRI_500 } from '../../../theme/colors';
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    // padding: "32px 0",
    // bottom: 0,
    // left: 0,
    // right: 0,
  },
  iconButton: {
    borderRadius: "50%",
    border: "2px solid !important",
    marginRight: "8px !important",
  },
  icon: {
    color: PRI_500,
  },
  text: {
    color: NTRL_700,
  },
}));
const Back = (props) => {
    const classes = useStyles();
    const {title = "Undefined", click = () => {}} = props;
    return (
      <div className={classes.root}>
        <IconButton className={classes.iconButton} onClick={click}>
          <ChevronLeftOutlined className={classes.icon} />
        </IconButton>
        <Typography variant="subtitle2" className={classes.text}>
            {title}
        </Typography>
      </div>
    );
}

export default Back

import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialAppointment = {
  layanan: {
    id_refpoli: "",
    id_poli: "",
    kd_poli: "",
    ref_layanan_nama: "",
    disabled: false,
  },
  dokter: {
    // peg_id: "100080",
    // nama_dokter: "DOKTER RSUDC",
    peg_id: "",
    nama_dokter: "",
    disabled: false,
  },

  produk: {
    ref_prod_id: "",
    ref_prod_nama: "",
    // ref_prod_hargajual_total: 0,
    disabled: false,
  },
  is_bpjs: false,
  waktu: "",
  no_rujukan: "",
  tanggal: "",
  total: 0,
  no_kartu: "",
  nik: "",
  jenis_kunjungan: "",
  jenis_penanggung: "", //1. BPJS 2.Asuransi 3.Umum
  penanggung_id: "",
  penanggung_nama: "",
  no_mr: "",
  no_telp: "",
};

const intialRujukan = {
  no_rujukan: "",
  tanggal: "",
  nik: "",
  no_kartu: "",
  kd_poli: "",
  nama_poli: "",
};
const initialState = {
  vaksin: {
    produkId: "",
    tglKunjungan: "",
  },
  igd: {
    keluhan: "",
  },
  historyAppointment: [],
  historyAppDetail: "",
  rujukanList: [],
  rujukan: localStorage.getItem("rujukan")
    ? JSON.parse(localStorage.getItem("rujukan"))
    : intialRujukan,
  appointment: localStorage.getItem("appointment")
    ? JSON.parse(localStorage.getItem("appointment"))
    : initialAppointment,
  tipe_appointment: localStorage.getItem("tipe_appointment") || "",
};

const setIGD = (state, action) => {
  return updateObject(state, {
    igd: {
      ...state.igd,
      [action.data.key]: action.data.value,
    },
  });
};

const setVaksin = (state, action) => {
  return updateObject(state, {
    vaksin: {
      ...state.vaksin,
      [action.data.key]: action.data.value,
    },
  });
};

const setTipeAppointment = (state, action) => {
  localStorage.setItem("tipe_appointment", action.data);
  return updateObject(state, {
    tipe_appointment: action.data,
  });
};

const setAppointment = (state, action) => {
  localStorage.setItem(
    "appointment",
    JSON.stringify({
      ...state.appointment,
      ...action.data,
    })
  );
  return updateObject(state, {
    appointment: {
      ...state.appointment,
      ...action.data,
    },
  });
};
const resetAppointment = (state, action) => {
  localStorage.removeItem("appointment");
  localStorage.removeItem("rujukan");
  localStorage.removeItem("tipe_appointment");
  return updateObject(state, {
    appointment: initialAppointment,
    rujukan: intialRujukan,
  });
};

const resetVaksin = (state) => {
  return updateObject(state, {
    vaksin: initialState.vaksin,
  });
};
const setHistoryAppointment = (state, action) => {
  return updateObject(state, {
    historyAppointment: action.data,
  });
};
const setHistoryAppDetail = (state, action) => {
  return updateObject(state, {
    historyAppDetail: action.data,
  });
};
const setRujukanList = (state, action) => {
  return updateObject(state, {
    rujukanList: action.data,
  });
};
const setRujukan = (state, action) => {
  localStorage.setItem(
    "rujukan",
    JSON.stringify({
      ...state.rujukan,
      ...action.data,
    })
  );
  return updateObject(state, {
    rujukan: {
      ...state.rujukan,
      ...action.data,
    },
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_VAKSIN:
      return setVaksin(state, action);
    case actionTypes.RESET_VAKSIN:
      return resetVaksin(state, action);
    case actionTypes.SET_APPOINTMENT:
      return setAppointment(state, action);
    case actionTypes.RESET_APPOINTMENT:
      return resetAppointment(state, action);
    case actionTypes.SET_HISTORY_APPOINTMENT:
      return setHistoryAppointment(state, action);
    case actionTypes.SET_HISTORY_APPOINTMENT_DETAIL:
      return setHistoryAppDetail(state, action);
    case actionTypes.SET_RUJUKAN_LIST:
      return setRujukanList(state, action);
    case actionTypes.SET_RUJUKAN:
      return setRujukan(state, action);
    case actionTypes.SET_TIPE_APPOINTMENT:
      return setTipeAppointment(state, action);
    case actionTypes.SET_IGD:
      return setIGD(state, action);
    default:
      return state;
  }
};

export default reducer;

import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import ImgContribute from "../ImgContribute";
import languageImg from "../../assets/language.png";
import React from "react";
import { connect } from "react-redux";
import { setBahasa, setBahasaOpen } from "../../redux/actions";

const useStyles = makeStyles(() => ({
  root: {
    padding: "16px 0",
  },
  langContainer: {
    padding: "16px 0",
    overflowX: "hidden",
  },
  dialogImg: {
    height: "250px",
    width: "auto",
  },
}));
const DialogBahasa = (props) => {
  const classes = useStyles();
  const { stateBahasaOpen, actSetBahasa, actSetBahasaOpen } = props;
  const handleBahasa = (bahasa) => {
    actSetBahasa(bahasa);
    actSetBahasaOpen(false);
  };
  return (
    <>
      <Dialog open={stateBahasaOpen}>
        <Container className={classes.langContainer}>
          <Grid container spacing={2}>
            <Grid xs={12} item container justifyContent={"center"}>
              <ImgContribute
                title="People illustrations by Storyset"
                src={languageImg}
                alt="Contribute"
                link="https://storyset.com/people"
              />
            </Grid>
            <Grid xs={12} item>
              <Typography align="center" variant="body1">
                Bahasa yang digunakan / Language
              </Typography>
            </Grid>
            <Grid xs={12} item>
              <Button
                variant="contained"
                onClick={() => handleBahasa("indonesia")}
              >
                Bahasa Indonesia
              </Button>
            </Grid>
            <Grid xs={12} item>
              <Button
                variant="outlined"
                onClick={() => handleBahasa("english")}
              >
                English
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

// DialogBahasa.propTypes = {
//   open: PropTypes.bool,
// };

const mapStateToProps = (state) => {
  return {
    stateBahasa: state.app.bahasa,
    stateBahasaOpen: state.app.bahasaOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actSetBahasa: (bahasa) => dispatch(setBahasa(bahasa)),
    actSetBahasaOpen: (bahasa) => dispatch(setBahasaOpen(bahasa)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DialogBahasa);

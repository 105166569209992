import CalendarToday from "@mui/icons-material/CalendarToday";
import History from "@mui/icons-material/History";
import Home from "@mui/icons-material/Home";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { resetAppointment } from "../../redux/actions";

const useStyles = makeStyles(() => ({
  paper: {
    padding: "8px 16px",
    position: "fixed",
    bottom: "32px",
    left: "0",
    right: "0",
    margin: "0px 16px",
    zIndex: "100",
  },
  disabled: {
    opacity: 0.5,
  },
}));
const BottomNav = (props) => {
  const { stateUser, stateBahasa, actResetAppointment } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const toHistory = () => {
    stateUser && navigate("/history");
  };
  return (
    <>
      <Paper className={classes.paper}>
        <Grid container justifyContent={"center"} alignItems={"center"}>
          <Grid
            item
            xs={4}
            container
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            onClick={() => navigate("/home")}
          >
            <IconButton>
              <Home />
            </IconButton>
            <Typography variant="body1" align="center">
              Home
            </Typography>
          </Grid>
          <Grid
            item
            xs={4}
            container
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            // className={classes.disabled}
            onClick={() => {
            actResetAppointment();
            navigate(stateUser ? "/choose-assurance" : "/register");
            }}
          >
            <IconButton>
              <CalendarToday />
            </IconButton>
            <Typography variant="body1" align="center">
              {stateBahasa === "indonesia" ? "Buat Janji" : "Appointment"}
            </Typography>
          </Grid>{" "}
          <Grid
            item
            xs={4}
            container
            justifyContent={"center"}
            direction={"column"}
            alignItems={"center"}
            className={!stateUser ? classes.disabled : ""}
            onClick={toHistory}
          >
            <IconButton>
              <History />
            </IconButton>
            <Typography variant="body1" align="center">
              {stateBahasa === "indonesia" ? "Riwayat" : "History"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    stateBahasa: state.app.bahasa,
    stateUser: state.auth.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actResetAppointment: (body) => dispatch(resetAppointment(body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BottomNav);

import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import doctorImg from "../../assets/family_patient.svg";
import { connect } from "react-redux";
import { logout } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    padding: "3px",
    borderRadius: "50% !important",
    cursor: "pointer",
  },
  img: {
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    objectFit: "contain",
  },
  imgLarge: {
    width: "80px",
    height: "80px",
    borderRadius: "50%",
    objectFit: "contain",
  },
}));
const ProfileBar = (props) => {
  const { stateUser, handleClick, type } = props;
  const classes = useStyles();
  const navigate = useNavigate();
  const handleProfile = () => {
    // handleClick()
    if (handleClick) {
      return handleClick();
    }
    return stateUser ? navigate("/profile") : navigate("/login");
  };
  return (
    <div>
      <Grid container>
        <Grid item xs={12} container justifyContent="end">
          <Paper className={classes.root}>
            <img
              src={doctorImg}
              alt="profile"
              className={type === "big" ? classes.imgLarge : classes.img}
              onClick={handleProfile}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
    // <Paper className={classes.root}>
    //   <Grid
    //     container
    //     spacing={2}
    //     className={classes.root}
    //     alignItems="center"
    //     justifyContent="space-around"
    //   >
    //     <Grid item>
    //       <img src={doctorImg} alt="profile" className={classes.img} />
    //     </Grid>
    //     <Grid item>
    //       <Typography variant="subtitle2">
    //         Hi, {stateUser ? stateUser.nama : "Pengunjung"}
    //       </Typography>
    //       {/* <Typography variant="subtitle2">Login</Typography> */}
    //     </Grid>
    //     <Grid item>
    //       {stateUser && (
    //         <IconButton onClick={() => actLogout(null)}>
    //           <Logout />
    //         </IconButton>
    //       )}
    //       {!stateUser && (
    //         <IconButton onClick={toLogin}>
    //           <Login />
    //         </IconButton>
    //       )}
    //     </Grid>
    //   </Grid>
    // </Paper>
  );
};

ProfileBar.propTypes = {
  handleClick: PropTypes.func,
  type: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    stateUser: state.auth.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    actLogout: (actions) => dispatch(logout(actions)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProfileBar);

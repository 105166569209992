import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import doctorImg from "../../assets/doctors.png";
import { PRI_400 } from "../../theme/colors";
import makeStyles from "@mui/styles/makeStyles";
import ModalNotification from "../ModalNotification";
import handSucc from "../../assets/hand_succ.png";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  batalAppointment,
  getHistoryAppDetail,
  setDetailDoctor,
} from "../../redux/actions";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Close from "@mui/icons-material/Close";
import failedImg from "../../assets/hand_fail.png";
import QRCode from "react-qr-code";
import LocalAtm from "@mui/icons-material/LocalAtm";
import QrCode from "@mui/icons-material/QrCode";
import SnackBarCustom from "../SnackBarCustom";
import { getDiffDay } from "../../helpers/common/utility";
import { BASE_URL_IMG } from "../../helpers/common/config";
import { useNavigate } from "react-router-dom";
import { getDetailDokter } from "../../redux/actions/master";

const useStyles = makeStyles({
  img: {
    width: "50px",
    height: "50px",
    objectFit: "contain",
    borderRadius: "50%",
  },
  dateContainer: {
    borderRadius: "8px !important",
    color: "#FFF !important",
    backgroundColor: PRI_400,
    padding: "8px",
  },
  cancelStamp: {
    position: "absolute",
    padding: "8px",
    border: "3px solid red",
    backgroundColor: "#FFF",
    opacity: "0.8",
    color: "red",
    fontSize: "16px",
    width: "50vw",
    fontWeight: 700,
    textAlign: "center",
  },
  qrWrapper: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
const HistoryCard = (props) => {
  const {
    stateBahasa,
    title,
    subtitle,
    // eslint-disable-next-line no-unused-vars
    time,
    tanggal,
    cancelled,
    rkunId,
    actBatalAppointment,
    actGetHistoryAppDetail,
    stateHistoryDetail,
    reservasi,
    onPayment,
    paymentStatus,
    isBPJS,
    photo,
    doctorId,
    actGetDetailDokter,
    cancellable,
  } = props;
  const navigate = useNavigate();
  const classes = useStyles();
  const [notif, setNotif] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [alasan, setAlasan] = React.useState("");
  const [openFail, setOpenFail] = React.useState({
    open: false,
    message: "",
  });
  const [receipt, setReceipt] = React.useState({
    open: false,
  });
  const handleFail = (msg) => {
    setOpenFail({
      open: true,
      message: msg,
    });
  };
  const handleCancel = () => {
    actBatalAppointment(
      {
        rkun_id: rkunId,
        alasan: alasan,
      },
      {
        success: () => setDone(true),
        failed: (msg) => handleFail(msg),
      }
    );
  };
  const handleReceipt = () => {
    actGetHistoryAppDetail(
      {
        rkun_id: rkunId,
      },
      () =>
        setReceipt({
          open: true,
        })
    );
  };
  const isPaid = () => {
    // if (paymentStatus === "settlement") {
    //   return true;
    // }
    return false;
    // switch (paymentStatus) {
    //   case "pending":
    //     return false;
    //   case "settlement":
    //     return true;
    //   default:
    //     return false;
    // }
  };
  return (
    <>
      <SnackBarCustom />
      <Dialog open={receipt.open}>
        <Paper
          sx={{
            padding: "16px",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="end">
              <IconButton
                onClick={() =>
                  setReceipt({
                    open: false,
                  })
                }
              >
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12} container direction="column">
              <Typography variant="h3" align="center">
                RSUD CENGKARENG
              </Typography>
              <Typography variant="body2" align="center">
                JL. Bumi Cengkareng Indah No.1, Cengkareng Tim., Kecamatan
                Cengkareng, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta
                11730
              </Typography>
              <Typography
                variant="body2"
                align="center"
                color="blue"
                onClick={() =>
                  window.open("https://goo.gl/maps/n1MF6RVaRYdUfR5q6", "_blank")
                }
              >
                Google Maps
              </Typography>
            </Grid>
            <Grid item xs={12} container direction="column">
              <Typography variant="subtitle1" align="center">
                {`${stateHistoryDetail.is_bpjs ? "BPJS" : "NON-BPJS"} / ${
                  stateHistoryDetail.is_lansia ? "LANSIA" : "NON-LANSIA"
                }`}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <div className={classes.qrWrapper}>
                {stateHistoryDetail.mrn && (
                  <QRCode value={stateHistoryDetail.mrn} size={192} />
                )}
                {cancelled && (
                  <Box className={classes.cancelStamp}>CANCELED</Box>
                )}
                {/* <QRCode
                  value={
                    stateHistoryDetail.rkunid
                      ? stateHistoryDetail.rkunid.toString()
                      : "null"
                  }
                  size={128}
                /> */}
              </div>
            </Grid>
            <Grid item xs={12}>
              <Typography align="center" variant="subtitle1">
                {stateHistoryDetail.mrn}
              </Typography>
            </Grid>
            {stateHistoryDetail.is_bpjs &&
              stateHistoryDetail.rencana_kunjungan.rkun_waktu !== 1 && (
                <Grid item xs={12}>
                  <Typography variant="h5" align="center" color="red">
                    MOHON DATANG SEBELUM JAM 12 UNTUK CETAK SEP
                  </Typography>
                </Grid>
              )}
            <Grid item xs={12}>
              <Typography variant="body1">
                {stateBahasa === "indonesia"
                  ? "Nomor antrian anda:"
                  : "Your queue:"}
              </Typography>
              <Box
                sx={{
                  padding: "3px",
                  borderRadius: "3px",
                  border: "2px solid " + PRI_400,
                }}
              >
                <Typography variant="h3" align="center">
                  {stateHistoryDetail.noantri}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">Poli</Typography>
              <Box
                sx={{
                  padding: "3px",
                  borderRadius: "3px",
                  border: "2px solid " + PRI_400,
                }}
              >
                <Typography variant="body1" align="center">
                  {stateHistoryDetail.poli}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {stateBahasa === "indonesia" ? "Tanggal Tindakan:" : "Date:"}
              </Typography>
              <Box
                sx={{
                  padding: "3px",
                  borderRadius: "3px",
                  border: "2px solid " + PRI_400,
                }}
              >
                <Typography variant="subtitle1" align="center">
                  {stateHistoryDetail.tglkunjung}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                {stateBahasa === "indonesia"
                  ? "Estimasi waktu tindakan:"
                  : "Estimated time:"}
              </Typography>
              <Box
                sx={{
                  padding: "3px",
                  borderRadius: "3px",
                  border: "2px solid " + PRI_400,
                }}
              >
                <Typography variant="subtitle1" align="center">
                  {stateHistoryDetail.pesanfoot1}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                {stateBahasa === "indonesia"
                  ? "SILAHKAN MENUNGGU NOMOR ANTRIAN DIPANGGIL, MOHON DATANG SETENGAH JAM SEBELUM ESTIMASI"
                  : "PLEASE WAIT FOR YOUR TURN, AND COME HALF HOUR EARLIER"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="center">
                {stateBahasa === "indonesia"
                  ? "TERIMAKASIH ATAS KEPERCAYAAN ANDA"
                  : "THANK YOU FOR YOUR TRUST"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <Dialog open={notif}>
        <Paper sx={{ padding: "8px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="end">
              <IconButton onClick={() => setNotif(false)}>
                <Close />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={stateBahasa === "indonesia" ? "Alasan Batal" : "Reason"}
                value={alasan}
                onChange={(e) => setAlasan(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                disabled={!alasan || !reservasi}
                onClick={handleCancel}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Dialog>
      <ModalNotification
        open={done}
        setOpen={setDone}
        title={stateBahasa === "indonesia" ? "Dibatalkan" : "Cancelled"}
        subtitle={""}
        img={handSucc}
        actAbort={() => window.location.reload()}
      />
      <ModalNotification
        open={openFail.open}
        setOpen={(open) => setOpenFail({ ...openFail, open: open })}
        title={"Failed"}
        subtitle={openFail.message}
        img={failedImg}
      />

      <Paper
        sx={{
          padding: "15px 10px",
        }}
        variant="outlined"
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            container
            spacing={2}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Grid item>
              <Box
                onClick={() =>
                  actGetDetailDokter(doctorId, () =>
                    navigate(`/doctor-detail/${doctorId}`)
                  )
                }
                // onClick={() => navigate(`/doctor-detail/${doctorId}`)}
              >
                {photo && (
                  <img
                    src={BASE_URL_IMG + "/" + photo}
                    alt=""
                    className={classes.img}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = doctorImg;
                    }}
                  ></img>
                )}
                {!photo && (
                  <img src={doctorImg} alt="" className={classes.img} />
                )}
              </Box>
            </Grid>
            <Grid item>
              <div
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <Stack>
                  <Typography
                    noWrap
                    variant="subtitle2"
                    sx={{
                      width: "10rem",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      width: "10rem",
                    }}
                  >
                    {subtitle}
                  </Typography>
                  <Typography
                    variant="body1"
                    noWrap
                    sx={{
                      width: "10rem",
                    }}
                  >
                    {tanggal}
                  </Typography>
                </Stack>
              </div>
            </Grid>
            <Grid item xs={2} container direction="column">
              <IconButton onClick={handleReceipt}>
                <QrCode />
              </IconButton>
              {!isBPJS && onPayment && (
                <IconButton onClick={onPayment} disabled={isPaid()}>
                  <LocalAtm />
                </IconButton>
              )}
            </Grid>
          </Grid>
          {cancellable && reservasi && (
            <Grid item xs={12}>
              <Button
                size="small"
                variant="outlined"
                onClick={() => setNotif(true)}
                disabled={cancelled || !reservasi}
              >
                {stateBahasa === "indonesia" ? "Batal" : "Cancel"}
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
};
HistoryCard.propTypes = {
  time: PropTypes.string,
  tanggal: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  // cancelled: PropTypes.bool.isRequired,
  rkunId: PropTypes.any.isRequired,
  reservasi: PropTypes.any.isRequired,
  photo: PropTypes.string,
  cancellable: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    // stateEmail: state.auth.email,
    stateBahasa: state.app.bahasa,
    stateHistoryDetail: state.register.historyAppDetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actBatalAppointment: (body, action) =>
      dispatch(batalAppointment(body, action)),
    actGetDetailDokter: (body, action) =>
      dispatch(getDetailDokter(body, action)),
    actGetHistoryAppDetail: (body, action) =>
      dispatch(getHistoryAppDetail(body, action)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HistoryCard);

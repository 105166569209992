import axios from "../../helpers/axios/axios";
import { setLoading } from ".";
import { BASE_URL } from "../../helpers/common/config";
import * as actionTypes from "../actions/types";

export const logout = (actions) => {
  actions && actions();
  return {
    type: actionTypes.LOGOUT,
  };
};
const setAuth = (payload) => {
  return {
    type: actionTypes.SET_AUTH,
    data: {
      token: payload.data.authToken,
      refreshToken: payload.data.refreshToken,
      user: payload.data.data_pasien,
    },
  };
};

const updateUser = (payload) => {
  return {
    type: actionTypes.UPDATE_USER,
    data: {
      user: payload.data,
    },
  };
};

export const login = (body, action) => {
  const url = BASE_URL + "/api/auth/login-pasien";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        action && action.success();
        return dispatch(setAuth(payload.data));
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan"
          );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const forgetPasswordRequest = (body, action) => {
  const url = BASE_URL + "/api/auth/forget-password/request";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        action &&
          action.success(
            payload.data && payload.data.data
              ? `Check WA : ${payload.data.data.no_telp} or Email : ${payload.data.data.email}`
              : "Check WA or E-mail"
          );
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan"
          );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const forgetPasswordValidate = (token, action) => {
  const url = BASE_URL + "/api/auth/forget-password/validate";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        headers: {
          "x-forget-token": token,
        },
      })
      .then((payload) => {
        action &&
          action.success(
            payload.data && payload.data.data ? payload.data.data.nama : ""
          );
      })
      .catch((err) => {
        action && action.failed("Token Expired");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const forgetPasswordChange = ({ token, password }, action) => {
  const url = BASE_URL + "/api/auth/forget-password/change";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(
        url,
        {
          password: password,
        },
        {
          headers: {
            "x-forget-token": token,
          },
        }
      )
      .then((payload) => {
        action &&
          action.success(
            payload.data && payload.data.data ? payload.data.data.nama : ""
          );
      })
      .catch((err) => {
        action && action.failed("Token Expired");
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const updateProfile = (body, action) => {
  const url = BASE_URL + "/api/auth/update-pasien";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action.success();
        dispatch(setAuth(payload.data));
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan"
          );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const setIdentitas = (payload) => {
  return {
    type: actionTypes.SET_IDENTITAS,
    data: {
      key: payload.key,
      value: payload.value,
    },
  };
};

export const resetIdentitas = () => {
  localStorage.removeItem("identitas");
  return {
    type: actionTypes.RESET_IDENTITAS,
  };
};

export const register = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-baru";
  // localStorage.setItem("daftarBaru", JSON.stringify(body));
  // return action && action.success && action.success();

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        localStorage.setItem("registerAttempt", 0);
        return action && action.success && action.success();
      })
      .catch((err) => {
        localStorage.setItem(
          "registerAttempt",
          localStorage.getItem("registerAttempt")
            ? parseInt(localStorage.getItem("registerAttempt")) + 1
            : 1
        );
        return (
          action &&
          action.success &&
          action.failed(
            err.response && err.response.data
              ? err.response.data.message
              : err.toString() + "Coba gunakan galeri, bukan kamera"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const registerUser = (body, action) => {
  const url = BASE_URL + "/api/auth/daftar-pasien";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        action && action.success();
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan"
          );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const checkPasien = (body, action) => {
  const url = BASE_URL + "/api/layanan/check-pasien";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          no_ktp: body.no_ktp,
        },
      })
      .then((payload) => {
        return action && action.success(payload.data.data);
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan",
            err.response.status
          );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const loginIGD = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-igd-lama";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        return action && action.success();
      })
      .catch((err) => {
        action &&
          action.failed(
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : "Ada Kesalahan",
            err.response.status
          );
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const registerIGD = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-igd-baru";
  // localStorage.setItem("daftarBaru", JSON.stringify(body));
  // return action && action.success && action.success();

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        localStorage.setItem("registerAttempt", 0);
        return action && action.success && action.success();
      })
      .catch((err) => {
        localStorage.setItem(
          "registerAttempt",
          localStorage.getItem("registerAttempt")
            ? parseInt(localStorage.getItem("registerAttempt")) + 1
            : 1
        );
        return (
          action &&
          action.success &&
          action.failed(
            err.response && err.response.data
              ? err.response.data.message
              : err.toString() + "Coba gunakan galeri, bukan kamera"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

import moment from "moment";
import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const hitungTime = () => {
  if (localStorage.getItem("rockportStart")) {
    if (localStorage.getItem("rockportFinish")) {
      return (
        Number(localStorage.getItem("rockportFinish")) -
        Number(localStorage.getItem("rockportStart"))
      );
    }
    return moment().valueOf() - Number(localStorage.getItem("rockportStart"));
  }
  return 0;
};
const initialState = {
  karyawan: localStorage.getItem("rockportKaryawan")
    ? JSON.parse(localStorage.getItem("rockportKaryawan"))
    : "",
  hasil: "",
  evaluasi: "",
  mulai: localStorage.getItem("rockportStart")
    ? Number(localStorage.getItem("rockportStart"))
    : 0,
  mulaiText: localStorage.getItem("rockportStart")
    ? moment(Number(localStorage.getItem("rockportStart"))).format("hh:mm:ss")
    : 0,
  finish: localStorage.getItem("rockportFinish")
    ? Number(localStorage.getItem("rockportFinish"))
    : 0,
  finishText: "",
  active: localStorage.getItem("rockportActive") == 1 ? true : false,
  time: hitungTime(),
  history: [],
  failed: false,
};
const setKaryawan = (state, action) => {
  if (action.data) {
    localStorage.setItem("rockportKaryawan", JSON.stringify(action.data));
  }
  return updateObject(state, {
    karyawan: action.data || "",
  });
};
const setHasil = (state, action) => {
  return updateObject(state, {
    hasil: action.data,
  });
};
const setTime = (state, action) => {
  return updateObject(state, {
    time: action.data,
  });
};
const resetHasil = (state, action) => {
  localStorage.removeItem("rockportStart");
  localStorage.removeItem("rockportFinish");
  localStorage.removeItem("rockportActive");
  return updateObject({
    ...initialState,
    mulai: 0,
    mulaiText: "",
    finish: 0,
    finishText: "",
    active: false,
    time: 0,
  });
};
const setEvaluasi = (state, action) => {
  return updateObject(state, {
    evaluasi: action.data,
  });
};
const setMulai = (state, action) => {
  localStorage.setItem("rockportStart", action.data);
  localStorage.setItem("rockportActive", 1);
  let text = moment(action.data).format("hh:mm:ss");
  return updateObject(state, {
    mulai: action.data,
    active: true,
    mulaiText: text,
    finish: 0,
    finishText: "",
    time: 0,
  });
};
const setStop = (state, action) => {
  let text = moment(action.data).format("hh:mm:ss");
  localStorage.removeItem("rockportActive");
  if (!localStorage.getItem("rockportFinish")) {
    localStorage.setItem("rockportFinish", action.data);
  }
  return updateObject(state, {
    finish: localStorage.getItem("rockportFinish")
      ? Number(localStorage.getItem("rockportFinish"))
      : action.data,
    finishText: text,
    active: false,
  });
};
const setHistory = (state, action) => {
  return updateObject(state, {
    history: action.data,
  });
};
const setFailed = (state, action) => {
  return updateObject(state, {
    failed: action.data,
  });
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_KEBUGARAN_KARYAWAN:
      return setKaryawan(state, action);
    case actionTypes.SET_KEBUGARAN_HASIL:
      return setHasil(state, action);
    case actionTypes.SET_KEBUGARAN_RESET:
      return resetHasil(state, action);
    case actionTypes.SET_KEBUGARAN_EVALUASI:
      return setEvaluasi(state, action);
    case actionTypes.SET_KEBUGARAN_MULAI:
      return setMulai(state, action);
    case actionTypes.SET_KEBUGARAN_STOP:
      return setStop(state, action);
    case actionTypes.SET_KEBUGARAN_TIME:
      return setTime(state, action);
    case actionTypes.SET_KEBUGARAN_HISTORY:
      return setHistory(state, action);
    case actionTypes.SET_KEBUGARAN_FAILED:
      return setFailed(state, action);
    default:
      return state;
  }
};

export default reducer;

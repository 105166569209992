// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASUREMENT_ID,
};

// export default app;
// const analytics = getAnalytics(app);

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const isSupported = () =>
  "Notification" in window &&
  "serviceWorker" in navigator &&
  "PushManager" in window;

export const requestPermission = () => {
  if (!isSupported()) {
    return;
  }
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Permission granted");
      // Initialize Firebase
      getToken(messaging, {
        vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
      }).then((currToken) => {
        if (currToken) {
          localStorage.setItem("fcm_token", currToken);
          console.log("token ", currToken);
        } else {
          localStorage.removeItem("fcm_token");
          console.log("cant get tokan");
        }
        // onMessage(messaging, (message) => {
        //   console.log("your message: ", message);

        //   // console.log("payload.data.url"+message.data.url)
        //   //window.location.replace("http://localhost:3000/"+message.data.url)
        // });
      });
    } else {
      localStorage.removeItem("fcm_token");
      console.log("Not granted");
    }
  });
};
export const onMessageListener = () => {
  console.log("awwwww====");
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload);
      resolve(payload);
    });
  });
};

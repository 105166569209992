import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import doctorImg from "../../../assets/dokter.png";
const useStyles = makeStyles(() => ({
  root: {
    cursor: "pointer",
  },
  textContent: {
    padding: "0 8px",
  },
  image: {
    height: "30px",
    width: "30px",
    objectFit: "contain",
  },
  paper: {
    width: "50px",
    height: "50px",
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  disabled: {
    opacity: "0.5",
  },
  container: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
const Small = (props) => {
  const classes = useStyles();
  const {
    title = "Undefined",
    // eslint-disable-next-line no-unused-vars
    text = "Undefined",
    img,
    disabled,
    click = () => {},
  } = props;
  return (
    <Grid
      container
      direction={"column"}
      justifyContent={"center"}
      alignItems={"center"}
      spacing={1}
      className={disabled ? classes.disabled : ""}
    >
      <Grid item>
        <Paper
          {...props}
          className={classes.paper}
          onClick={disabled ? () => {} : click}
        >
          <img
            src={img || doctorImg}
            alt="pelayanan"
            className={classes.image}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = doctorImg;
            }}
          />
        </Paper>
      </Grid>
      <Grid item zeroMinWidth>
        <Typography
          variant="body1"
          align="center"
          noWrap
          overflow="hidden"
          textOverflow="ellipsis"
          maxWidth="80px"
          // sx={{
          //   overflow: "hidden",
          //   textOverflow: "ellipsis",
          //   width: "50px",
          // }}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Small;

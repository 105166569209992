import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialState = {
  list: [],
  latestReqSKL: "",
};
const setKelahiran = (state, action) => {
  return updateObject(state, {
    list: action.data,
  });
};
const setLatestReqSKL = (state, action) => {
  return updateObject(state, {
    latestReqSKL: action.data,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_KELAHIRAN_LIST:
      return setKelahiran(state, action);
    case actionTypes.SET_LATEST_REQ_SKL:
      return setLatestReqSKL(state, action);
    default:
      return state;
  }
};

export default reducer;

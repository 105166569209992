import moment from "moment";
import axios from "../../helpers/axios/axios";
import { BASE_URL } from "../../helpers/common/config";
import { errorHandler, getDiffDay } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";
import { setLoading } from "./";
import { setSnackBar } from "./app";
import { logout } from "./auth";

export const setFormVaksin = (payload) => {
  return {
    type: actionTypes.SET_VAKSIN,
    data: {
      key: payload.key,
      value: payload.value,
    },
  };
};

export const resetVaksin = () => {
  return {
    type: actionTypes.RESET_VAKSIN,
  };
};

export const setIdentitasAuto = (payload) => {
  return {
    type: actionTypes.SET_IDENTITAS_AUTO,
    data: payload.data,
  };
};

export const setIGD = (payload) => {
  return {
    type: actionTypes.SET_IGD,
    data: payload,
  };
};

export const resetAppointment = () => {
  return {
    type: actionTypes.RESET_APPOINTMENT,
  };
};

export const setAppointment = (payload) => {
  return {
    type: actionTypes.SET_APPOINTMENT,
    data: payload,
  };
};

const setHistoryAppointment = (payload) => {
  return {
    type: actionTypes.SET_HISTORY_APPOINTMENT,
    data: payload.data,
  };
};
const setHistoryAppDetail = (payload) => {
  return {
    type: actionTypes.SET_HISTORY_APPOINTMENT_DETAIL,
    data: payload.data,
  };
};
export const setRujukanList = (payload) => {
  let arr = "";
  if (payload) {
    arr = payload.filter((el) => getDiffDay(el.tglKunjungan) <= 90);
    arr = arr.length > 0 ? arr : "";
  }
  return {
    type: actionTypes.SET_RUJUKAN_LIST,
    data: arr,
  };
};
export const setRujukan = (payload) => {
  return {
    type: actionTypes.SET_RUJUKAN,
    data: payload,
  };
};
export const setTipeAppointment = (payload) => {
  return {
    type: actionTypes.SET_TIPE_APPOINTMENT,
    data: payload,
  };
};
export const getIdentitas = (nik) => {
  const url = BASE_URL + "/api/master/get-nik";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          nik: nik,
        },
      })
      .then((payload) => {
        if (payload.data) {
          return dispatch(setIdentitasAuto(payload.data));
        }
      })
      .catch((err) => {
        // return errorHandler(dispatch, err);
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};

export const registerVaksin = (body, action) => {
  const url = BASE_URL + "/api/layanan/daftar-vaksin";

  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body)
      .then((payload) => {
        return action && action.success && action.success();
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return errorHandler(dispatch, err);
        }

        return (
          action &&
          action.success &&
          action.failed(
            err.response && err.response.data
              ? err.response.data.message
              : "Ada Kesalahan"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const makeAppointment = (body, action) => {
  const url = BASE_URL + "/api/layanan/ambil-antrian";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        return action && action.success(payload.data.data.rkun_id);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return errorHandler(dispatch, err);
        }
        return (
          action &&
          action.failed(
            err.response.data ? err.response.data.message : "Ada Kesalahan"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const makeAppointmentByPass = (body, action) => {
  const url = BASE_URL + "/api/layanan/ambil-antrian-admin";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        // headers: {
        //   "x-auth-token": state().auth.token,
        // },
      })
      .then((payload) => {
        return action && action.success(payload.data.data.rkun_id);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return errorHandler(dispatch, err);
        }
        return (
          action &&
          action.failed(
            err.response.data ? err.response.data.message : "Ada Kesalahan"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const getHistoryAppointment = (body, action) => {
  const url = BASE_URL + "/api/antrian/history";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          tipe: body.filter,
          id_poli: body.id_poli,
          is_today: body.is_today,
        },
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        return dispatch(setHistoryAppointment(payload.data));
      })
      .catch((err) => {
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const getHistoryAppDetail = (body, action) => {
  const url = BASE_URL + "/api/antrian/history/detail";
  return (dispatch, state) => {
    const bahasa = state().app.bahasa;
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: body,
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        action && action();
        return dispatch(setHistoryAppDetail(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 400) {
          return dispatch(
            setSnackBar({
              open: true,
              severity: "info",
              message:
                bahasa === "indonesia"
                  ? "Transaksi tidak ditemukan"
                  : "Transaction not found",
            })
          );
        }
        return errorHandler(dispatch, err);
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const batalAppointment = (body, action) => {
  const url = BASE_URL + "/api/antrian/cancel";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .post(url, body, {
        headers: {
          "x-auth-token": state().auth.token,
        },
      })
      .then((payload) => {
        return action && action.success();
        // return dispatch(setAuth(payload.data));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return errorHandler(dispatch, err);
        }
        return (
          action &&
          action.failed(
            err.response.data ? err.response.data.message : "Ada Kesalahan"
          )
        );
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

export const cariRujukan = (body, action) => {
  const url = BASE_URL + "/api/layanan/cari-rujukan";
  return (dispatch, state) => {
    dispatch(setLoading(true));
    axios
      .get(url, {
        params: {
          noka: body.no_kartu,
          jenis_kunjungan: body.jenis_kunjungan,
        },
        // timeout: 1000 * 10,
      })
      .then((payload) => {
        if (payload.data && payload.data.data) {
          return dispatch(setRujukanList(payload.data.data));
        }
        return dispatch(setRujukanList(""));
      })
      .catch((err) => {
        if (err.response.status === 401) {
          return errorHandler(dispatch, err);
        }
        action &&
          action.failed(
            "BPJS sedang Offline, mohon coba beberapa saat lagi."
            // err.response.data ? err.response.data.message : "Ada Kesalahan"
          );
        return dispatch(setRujukanList(""));
      })
      .finally(() => {
        return dispatch(setLoading(false));
      });
  };
};

import { updateObject } from "../../helpers/common/utility";
import * as actionTypes from "../actions/types";

const initialNotif = {
  title: "",
  subtitle: "",
  open: false,
  type: "",
  actAbort: null,
};

const initialSnackBar = {
  message: "",
  open: false,
  severity: "info",
};

const initialState = {
  loading: false,
  error: "",
  bahasa: "indonesia",
  bahasaOpen: false,
  notification: initialNotif,
  snackBar: initialSnackBar,
};

const resetNotif = (state, action) => {
  return updateObject(state, {
    notification: initialNotif,
  });
};

const setNotif = (state, action) => {
  return updateObject(state, {
    notification: {
      ...state.notification,
      ...action.data,
    },
  });
};

const setLoading = (state, action) => {
  return updateObject(state, {
    loading: action.data,
  });
};
const setError = (state, action) => {
  return updateObject(state, {
    error: action.data,
  });
};

const setBahasa = (state, action) => {
  return updateObject(state, {
    bahasa: action.data,
  });
};

const setBahasaOpen = (state, action) => {
  return updateObject(state, {
    bahasaOpen: action.data,
  });
};

const setSnackBar = (state, action) => {
  return updateObject(state, {
    snackBar: {
      ...state.snackBar,
      ...action.data,
    },
  });
};

const resetSnackBar = (state) => {
  return updateObject(state, {
    snackBar: initialSnackBar,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return setError(state, action);
    case actionTypes.SET_LOADING:
      return setLoading(state, action);
    case actionTypes.SET_BAHASA:
      return setBahasa(state, action);
    case actionTypes.SET_BAHASA_OPEN:
      return setBahasaOpen(state, action);
    case actionTypes.SET_NOTIF:
      return setNotif(state, action);
    case actionTypes.RESET_NOTIF:
      return resetNotif(state, action);
    case actionTypes.SET_SNACK_BAR:
      return setSnackBar(state, action);
    case actionTypes.RESET_SNACK_BAR:
      return resetSnackBar(state, action);
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ChevronLeftOutlined from "@mui/icons-material/ChevronLeftOutlined";
import makeStyles from "@mui/styles/makeStyles";
import { NTRL_100, PRI_400, PRI_500 } from "../../../theme/colors";
import { Grid, Stack } from "@mui/material";
const useStyles = makeStyles(() => ({
  root: {
    height: "200px",
    backgroundColor: PRI_400,
    // padding: "32px 0",
    // bottom: 0,
    // left: 0,
    // right: 0,
  },
  container: {
    padding: "32px"
  },
  iconButton: {
    borderRadius: "50%",
    border: "2px solid !important",
    marginRight: "8px !important",
  },
  icon: {
    color: PRI_500,
  },
  text: {
    color: NTRL_100,
  },
}));
const Back = (props) => {
  const classes = useStyles();
  const { title = "Undefined", subtitle, click = () => {} } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <IconButton className={classes.iconButton} onClick={click}>
            <ChevronLeftOutlined className={classes.icon} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Typography variant="subtitle2" className={classes.text}>
              {title}
            </Typography>
            <Typography variant="body1" className={classes.text}>
              {subtitle}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default Back;

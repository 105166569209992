import React from "react";
import Medium from "./Medium";
import Small from "./Small";
import AkuBicaraCardImg from "./AkuBicaraCardImg";
const CardImage = (props) => {
  let html = "";
  let { type } = props;
  switch (type) {
    case "small":
      html = <Small {...props} />;
      break;
    case "medium":
      html = <Medium {...props} />;
      break;
    case "akubicara":
      html = <AkuBicaraCardImg {...props} />;
      break;
    default:
      html = <Small {...props} />;
      break;
  }
  return <>{html}</>;
};

export default CardImage;

import * as actionTypes from "../actions/types";

export const setLoading = (val) => {
  return {
    type: actionTypes.SET_LOADING,
    data: val,
  };
};

export const setError = (val) => {
  return {
    type: actionTypes.SET_ERROR,
    data: val,
  };
};

export const setBahasa = (val) => {
  return {
    type: actionTypes.SET_BAHASA,
    data: val,
  };
};
export const setBahasaOpen = (val) => {
  return {
    type: actionTypes.SET_BAHASA_OPEN,
    data: val,
  };
};
export const setNotif = (val) => {
  return {
      type: actionTypes.SET_NOTIF,
      data: val,
  };
};

export const resetNotif = () => {
  return {
      type: actionTypes.RESET_NOTIF,
  };
};

export const setSnackBar = (val) => {
  return {
      type: actionTypes.SET_SNACK_BAR,
      data: val,
  };
};

export const resetSnackBar = () => {
  return {
      type: actionTypes.RESET_SNACK_BAR,
  };
};
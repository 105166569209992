import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";

const useStyles = makeStyles(() => ({
  img: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
    // maxHeight: "350px",
  },
  smallImg: {
    width: "auto",
    maxHeight: "160px",
  },
  btnText: {
    fontSize: "10px !important",
  },
  box: {
    display: "flex",
    justifyContent: "center",
  },
}));
const ImgContribute = (props) => {
  const { title, src, alt, link, type } = props;
  const classes = useStyles();
  const handleClick = () => {
    window.open(link, "_blank");
  };
  return (
    <>
      <Box className={classes.box}>
        <img src={src} alt={alt} className={type === 'small' ? classes.smallImg : classes.img} />
      </Box>
      <Button fullWidth variant="text" onClick={handleClick} className={classes.btnText}>
        {title}
      </Button>
      {/* <Typography align="center">{title}</Typography> */}
    </>
  );
};

export default ImgContribute;
